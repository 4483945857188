import React, { useMemo } from 'react';
import { geoPath, geoMercator } from 'd3-geo';

let padding = 20;

function MapBaseGroup(props) {
  const {
    width,
    height,
    extent,
    children,
    topPadding = 0,
    bottomPadding = 0,
    rightPadding = 0,
  } = props;

  let projection = useMemo(() => {
    return geoMercator()
      .fitExtent(
        [
          [padding, Math.round(Math.max(height * topPadding, padding))],
          [
            width - Math.round(Math.max(width * rightPadding, padding)),
            height - Math.max(bottomPadding, padding),
          ],
        ],
        extent
      )
      .clipExtent([
        [0.05, 0.05],
        [width, height],
      ]);
  }, [extent, width, height, topPadding, rightPadding, bottomPadding]);

  let path = useMemo(() => geoPath(projection), [projection]);

  return <g>{children({ projection, path })}</g>;
}

export default MapBaseGroup;
