import { lineString } from '@turf/helpers';
import { getCoord } from '@turf/invariant';
import produce from 'immer';
import clone from 'lodash.clonedeep';
import uniqWith from 'lodash.uniqwith';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import FixedHorizontalNodesSankey from '../components/data-visualisation/FixedHorizontalNodesSankey';
import ResponsiveChart from '../components/data-visualisation/ResponsiveChart';
import SmallMultipleMap from '../components/data-visualisation/SmallMultipleMap';
// import SmallMultipleMap from '../components/data-visualisation/SmallMultipleMap';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { SubChapterIntro } from '../components/simple/SubChapterIntro';
import { SubChapterTitle } from '../components/simple/SubChapterTitle';
import { Subtitle } from '../components/simple/Subtitle';
import { StepCounter } from '../components/StepCounter';
import useScrollama from '../hooks/useScrollama';

const data = {
  relations: [
    {
      id: 'ijburg-weesperplein',
      legs: [
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:2715',
          targetType: 'via',
          ratio: 0.55,
          travel_time: 11,
        },
        {
          source: 'stoparea:2715',
          sourceType: 'via',
          target: 'stoparea:30794',
          targetType: 'destination',
          ratio: 0.55,
          travel_time: 26,
        },
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:2540',
          targetType: 'via',
          ratio: 0.42,
          travel_time: 19,
        },
        {
          source: 'stoparea:2540',
          sourceType: 'via',
          target: 'stoparea:30794',
          targetType: 'destination',
          ratio: 0.42,
          travel_time: 26,
        },
      ],
    },
    {
      id: 'ijburg-amstel',
      legs: [
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:2540',
          targetType: 'via',
          ratio: 0.87,
          travel_time: 19,
        },
        {
          source: 'stoparea:2540',
          sourceType: 'via',
          target: 'stoparea:26091',
          targetType: 'destination',
          ratio: 0.87,
          travel_time: 29,
        },
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:2312',
          targetType: 'via',
          ratio: 0.05,
          travel_time: 8,
        },
        {
          source: 'stoparea:2312',
          sourceType: 'via',
          target: 'stoparea:26091',
          targetType: 'destination',
          ratio: 0.05,
          travel_time: 31,
        },
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:28772',
          targetType: 'via',
          ratio: 0.03,
          travel_time: 17,
        },
        {
          source: 'stoparea:28772',
          sourceType: 'via',
          target: 'stoparea:26091',
          targetType: 'destination',
          ratio: 0.03,
          travel_time: 36,
        },
      ],
    },
    {
      id: 'ijburg-zuid',
      legs: [
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:2540',
          targetType: 'via',
          ratio: 0.62,
          travel_time: 19,
        },
        {
          source: 'stoparea:2540',
          sourceType: 'via',
          target: 'stoparea:285518',
          targetType: 'destination',
          ratio: 0.62,
          travel_time: 34,
        },
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:28772',
          targetType: 'via',
          ratio: 0.13,
          travel_time: 16,
        },
        {
          source: 'stoparea:28772',
          sourceType: 'via',
          target: 'stoparea:30795',
          targetType: 'via',
          ratio: 0.13,
          travel_time: 27,
        },
        {
          source: 'stoparea:30795',
          sourceType: 'via',
          target: 'stoparea:285518',
          targetType: 'destination',
          ratio: 0.13,
          travel_time: 36,
        },
        {
          source: 'stoparea:2406',
          sourceType: 'origin',
          target: 'stoparea:28103',
          targetType: 'via',
          ratio: 0.15,
          travel_time: 24,
        },
        {
          source: 'stoparea:28103',
          sourceType: 'via',
          target: 'stoparea:285518',
          targetType: 'destination',
          ratio: 0.15,
          travel_time: 33,
        },
      ],
    },
  ],
  stops: [
    {
      type: 'Feature',
      properties: { id: 'stoparea:2406', name: 'IJburg' },
      geometry: { type: 'Point', coordinates: [5.004324, 52.350954] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:30794', name: 'Weesperplein' },
      geometry: { type: 'Point', coordinates: [4.9079675, 52.3611905] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:2715', name: 'Rietlandpark' },
      geometry: { type: 'Point', coordinates: [4.9342205, 52.37302975] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:2540', name: 'Centraal Station' },
      geometry: { type: 'Point', coordinates: [4.900779, 52.377492] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:26091', name: 'Amstelstation' },
      geometry: { type: 'Point', coordinates: [4.918531125, 52.346514375] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:28772', name: 'Station Ganzenhoef' },
      geometry: {
        type: 'Point',
        coordinates: [4.97364266666667, 52.323195666666699],
      },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:2312', name: 'Zuiderzeeweg' },
      geometry: { type: 'Point', coordinates: [4.96194, 52.371636] },
    },
    {
      type: 'Feature',
      properties: { id: 'stoparea:285518', name: 'Station Zuid' },
      geometry: { type: 'Point', coordinates: [4.874111, 52.339254] },
    },
    {
      type: 'Feature',
      properties: {
        id: 'stoparea:30795',
        frequency: 723,
        routes: [50, 53, 54],
        name: 'Van der Madeweg',
      },
      geometry: { type: 'Point', coordinates: [4.930328, 52.3296535] },
    },
    {
      type: 'Feature',
      properties: {
        id: 'stoparea:28103',
        frequency: 1137,
        routes: [44, 47, 49, 50, 54, 66, 755, 757],
        name: 'Station Bijlmer ArenA',
      },
      geometry: {
        type: 'Point',
        coordinates: [4.94729133333333, 52.311633833333303],
      },
    },
  ],
};

let getProperty = (property) => (feature) => feature.properties[property];
let getId = getProperty('id');
let getName = getProperty('name');

const findFeatureByProperty = (property) => (comparator) => (feature) =>
  feature.properties[property] === comparator;
const findFeatureById = findFeatureByProperty('id');
const findFeatureByType = findFeatureByProperty('type');

const addType = (feature, type) =>
  produce(feature, (draft) => {
    draft.properties.type = type;
  });

const Background = styled('div')`
  background: var(--light-gray);
  min-width: 100%;
`;

function Transfers() {
  const [activeStep, setActiveStep] = useState('ijburg-weesperplein');

  const handleStepEnter = useCallback((evt) => {
    let { step } = evt.element.dataset;

    if (step) {
      setActiveStep(step);
    }
  }, []);

  useScrollama(
    {
      step: '#traveltime-container [data-step]',
      container: '#traveltime-container',
    },
    handleStepEnter,
    'onStepEnter'
  );

  let relations = clone(data.relations);
  let stops = clone(data.stops);

  let activeRelation = relations.find((relation) => relation.id === activeStep);

  let activeStops = stops.filter((stop) =>
    activeRelation.legs.find(
      (leg) => leg.source === getId(stop) || leg.target === getId(stop)
    )
  );

  let steps = relations.map((rel) => {
    let item = { id: rel.id };
    let legs = [];
    let itemStops = [];

    for (let leg of rel.legs) {
      let source = data.stops.find(findFeatureById(leg.source));
      let target = data.stops.find(findFeatureById(leg.target));

      source = addType(source, leg.sourceType);
      target = addType(target, leg.targetType);

      itemStops.push(source, target);

      legs.push(
        lineString([getCoord(source), getCoord(target)], {
          id: `${getId(source)}-${getId(target)}`,
          ratio: leg.ratio,
          travel_time: leg.travel_time,
        })
      );
    }

    let origin = itemStops.find(findFeatureByType('origin'));
    let destination = itemStops.find(findFeatureByType('destination'));

    return produce(item, (draft) => {
      draft.legs = legs;
      draft.stops = uniqWith(itemStops, (a, b) => getId(a) === getId(b));
      draft.origin = origin;
      draft.destination = destination;
    });
  });

  return (
    <>
      <SubChapterIntro>
        <Subtitle>Hoofdstuk 2.2</Subtitle>
        <SubChapterTitle>Nieuwe lijnen, nieuwe keuzes</SubChapterTitle>
        <p>
          In dit hoofdstuk is te zien wat de impact is van de Noord/Zuidlijn op
          hoe mensen keuzes maken in het Amsterdamse OV.
        </p>
      </SubChapterIntro>
      <Background>
        <SideScrollingGrid>
          <SideScrollingGrid.Content>
            <ResponsiveChart>
              {(dimensions) => (
                <FixedHorizontalNodesSankey
                  {...dimensions}
                  stops={activeStops.map((d) => d.properties)}
                  legs={activeRelation.legs}
                />
              )}
            </ResponsiveChart>
          </SideScrollingGrid.Content>
          <SideScrollingGrid.SideStory id="traveltime-container">
            {steps.map((step) => (
              <Step key={step.id} data-step={step.id}>
                <h4>
                  Reizen tussen {getName(step.origin)} en{' '}
                  {getName(step.destination)}
                </h4>
                <p>
                  Dit zijn reizen die veel mensen in Amsterdam dagelijks maken:
                  herkomst- en bestemmingshaltes met een sterke relatie.
                </p>
                <SmallMultipleMap {...step} />
                <StepCounter
                  steps={steps.map((s) => s.id)}
                  activeStep={step.id}
                />
              </Step>
            ))}
          </SideScrollingGrid.SideStory>
        </SideScrollingGrid>
      </Background>
    </>
  );
}

export default Transfers;
