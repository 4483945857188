import { createGlobalStyle } from 'styled-components/macro';
import { modularScale } from '../helpers/modular-scale';

import RobotoWoff from '../fonts/subset-Roboto-Regular.woff';
import RobotoWoff2 from '../fonts/subset-Roboto-Regular.woff2';
import RobotoBoldWoff from '../fonts/subset-Roboto-Bold.woff';
import RobotoBoldWoff2 from '../fonts/subset-Roboto-Bold.woff2';
import RobotoCondensedWoff from '../fonts/subset-RobotoCondensed-Regular.woff';
import RobotoCondensedWoff2 from '../fonts/subset-RobotoCondensed-Regular.woff2';
import RobotoCondensedBoldWoff from '../fonts/subset-RobotoCondensed-Bold.woff';
import RobotoCondensedBoldWoff2 from '../fonts/subset-RobotoCondensed-Bold.woff2';

import HarmoniaSansProSemiBoldWoff from '../fonts/39C765_0_0.woff';
import HarmoniaSansProSemiBoldWoff2 from '../fonts/39C765_0_0.woff2';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), local('RobotoCondensed'),
        url(${RobotoCondensedWoff2}) format('woff2'),
        url(${RobotoCondensedWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold'), local('RobotoCondensedBold'),
        url(${RobotoCondensedBoldWoff}) format('woff2'),
        url(${RobotoCondensedBoldWoff2}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'),
        url(${RobotoWoff2}) format('woff2'),
        url(${RobotoWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url(${RobotoBoldWoff2}) format('woff2'),
        url(${RobotoBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
  font-family: 'Harmonia Sans Pro';
  src: url(${HarmoniaSansProSemiBoldWoff2}) format('woff2'),
    url(${HarmoniaSansProSemiBoldWoff}) format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

  html {
    box-sizing: border-box;
    font-size: ${modularScale(0)};
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  :root {
    --body-font: Roboto, -apple-system, 
    BlinkMacSystemFont, 
    "Segoe UI", 
    Roboto, 
    "Helvetica Neue", 
    sans-serif;
    --detail-font: 'Harmonia Sans Pro', Avenir, Futura, sans-serif;
    --red: #ec0001;
    --red-transparent: hsla(360, 100%, 46%, 0.3);
    --light-gray: #f0f0f3;
    --roboto-condensed: 'Roboto Condensed', -apple-system, 
    BlinkMacSystemFont, 
    "Segoe UI", 
    Roboto, 
    "Helvetica Neue", 
    sans-serif;
    --roboto: var(--body-font);
    --harmonia: var(--detail-font);
  }

  #root {
    min-height: 100%;
  }

  body {
    margin: 0;
    font-family: var(--body-font);
    font-variant-ligatures: common-ligatures;
    line-height: 1.5;
    min-height: 100%;
    position: relative;
  }

  h1, h2, h3, h4 {
    font-family: var(--harmonia);
    font-weight: 600;
  }

  h4 {
    margin-bottom: 1em;
  }

  p {
    margin: 1.5em 0;
    max-width: 27.5em;
  }
  
  a {
  color: var(--red);
  }

  ul, ol, dl {
    list-style-type: none;
    padding-left: 0;
  }

  dt, dd {
    margin-left: 0;
  }

  #root {
    min-height: 100%;
  }
`;

export default GlobalStyles;
