import { ribbon as createRibbonGenerator } from 'd3-chord';
import React, { useCallback, useMemo } from 'react';
import { getLinkValue } from './helpers/getLinkValue';
import { sourceOrTarget } from './helpers/sourceOrTarget';

export function Ribbons(props) {
  const {
    activeChords,
    activeStep,
    selectedGroup,
    fill,
    innerRadius,
    ribbons,
  } = props;

  const ribbon = useMemo(
    () => createRibbonGenerator().radius(innerRadius),
    [innerRadius]
  );

  const style = useCallback(
    (ribbon) => {
      let style = {
        opacity: 1,
        fill: 'var(--red)',
        fillOpacity: 0.75,
      };

      // Use the scale only if we're showing change
      if (activeStep === 'change')
        style.fill = fill(
          getLinkValue(ribbon, sourceOrTarget(ribbon, selectedGroup))
        );

      if (selectedGroup === null || selectedGroup === undefined) return style;

      if (selectedGroup !== null) {
        style.opacity = 0;
      }

      if (
        ribbon.source.id === selectedGroup ||
        ribbon.target.id === selectedGroup
      ) {
        style.opacity = 1;
      }

      return style;
    },
    [activeStep, fill, selectedGroup]
  );

  const byActiveChords = useCallback(
    (ribbon) => {
      if (activeChords[0] === 'false') return false;
      if (activeChords[0] === '*') return true;

      if (activeChords.length === 1) {
        return (
          activeChords.includes(`${ribbon.source.id}`) ||
          activeChords.includes(`${ribbon.target.id}`)
        );
      }

      if (activeChords.length > 1) {
        // return false early if the id's are the same to avoid false positives (ie. travel within zones)
        if (ribbon.source.id === ribbon.target.id) return false;

        return (
          activeChords.includes(`${ribbon.source.id}`) &&
          activeChords.includes(`${ribbon.target.id}`)
        );
      }
    },
    [activeChords]
  );

  return (
    <g className="ribbons">
      {ribbons.filter(byActiveChords).map((d) => (
        <path
          key={`link-${d.source.index}-${d.target.index}`}
          d={ribbon(d)}
          {...style(d)}
        />
      ))}
    </g>
  );
}
