import * as React from 'react';
import along from '@turf/along';
import length from '@turf/length';
import { getCoord } from '@turf/invariant';

const findProperty = (property) => (value) => (d) =>
  d.properties[property] === value;
const getProperty = (property) => (feature) => feature.properties[property];

const getShortName = getProperty('route_short_name');
const getActive = getProperty('active');
const getId = getProperty('route_id');
const getStatus = getProperty('status');

const sortOrder = [
  'new',
  'changed-new-state',
  'changed-old-state',
  'removed',
  'unchanged',
];

export default function RouteLayer({
  activeProperties,
  getRouteComponent,
  data,
  path,
  projection,
  getStroke,
  hasStrokeDasharray,
  showTags,
}) {
  const [features, setFeatures] = React.useState();

  React.useEffect(() => {
    let features = data.features.map((feature) => {
      let featureIsActive = true;

      if (Object.keys(activeProperties).length > 0) {
        // for each feature property in activeProperties
        featureIsActive = Object.keys(activeProperties).reduce((acc, key) => {
          // create an accessor
          let findActiveProperty = findProperty(key);

          // if the active property is an array (eg. multiple types of that property are active)
          if (Array.isArray(activeProperties[key])) {
            // return acc && true if some property in the activeProperty array is true
            return (
              acc &&
              activeProperties[key].some((active) => {
                let matchFeature = findActiveProperty(active);
                return matchFeature(feature);
              })
            );
          } else {
            // return acc && true if the activeProperty is matched
            let matchFeature = findActiveProperty(activeProperties[key]);
            return acc && matchFeature(feature);
          }
        }, true);
      }

      return Object.assign({}, feature, {
        properties: Object.assign({}, feature.properties, {
          active: featureIsActive,
        }),
      });
    });

    let sortedFeatures = [...features].sort((a, b) => {
      return (
        sortOrder.findIndex((d) => d === getStatus(b)) -
        sortOrder.findIndex((d) => d === getStatus(a))
      );
    });

    setFeatures(sortedFeatures);
  }, [data, activeProperties]);

  return (
    <g className="routes">
      {features && (
        <g className="paths">
          {features.map((f) => {
            let dataset = Object.keys(f.properties).reduce((acc, key) => {
              return Object.assign(acc, {
                [`data-${key}`]: f.properties[key],
              });
            }, {});

            const RouteComponent = getRouteComponent(f);

            return (
              <RouteComponent
                key={`route-${data.name}-${getId(f)}`}
                d={path(f)}
                stroke={getStroke(f)}
                strokeDasharray={
                  hasStrokeDasharray && getStatus(f) === 'changed-old-state'
                    ? '4'
                    : ''
                }
                {...dataset}
                opacity={getActive(f) ? 1 : 0}
              />
            );
          })}
        </g>
      )}

      {showTags && features && (
        <g className="tags">
          {features.filter(getActive).map((f) => {
            const middle = along(f, length(f) * 0.3);
            const [x, y] = projection(getCoord(middle));

            return (
              <g key={`label-${data.name}-${getId(f)}`}>
                <circle
                  cx={x}
                  cy={y}
                  r={9}
                  fill={'white'}
                  fillOpacity={0.8}
                  stroke={getStroke(f)}
                  strokeWidth={2}
                />
                <text
                  x={x}
                  y={y}
                  dy={3}
                  textAnchor={'middle'}
                  fontFamily={'var(--roboto-condensed)'}
                  fontSize={'9px'}
                  fontWeight={'400'}
                >
                  {getShortName(f)}
                </text>
              </g>
            );
          })}
        </g>
      )}
    </g>
  );
}
