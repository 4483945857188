import * as React from 'react';
import styled from 'styled-components/macro';

const TooltipText = styled.text`
  user-select: none;
  font-family: var(--roboto-condensed);
  line-height: 0.8;
`;

export function Tooltip({
  id,
  transform,
  anchor,
  hasBackground,
  children,
  ...rest
}) {
  return (
    <g>
      <defs>
        <filter
          x="-0.025"
          y="-0.025"
          width="1.05"
          height="1.05"
          id={`background-${id}`}
          shapeRendering="geometricPrecision"
        >
          <feFlood floodColor="white" floodOpacity={0.8} />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
      </defs>
      <TooltipText
        dy={4}
        textAnchor={anchor}
        filter={hasBackground ? `url(#background-${id})` : undefined}
        fill="black"
        transform={transform}
        {...rest}
      >
        {children}
      </TooltipText>
      <TooltipText
        dy={4}
        textAnchor={anchor}
        fill="black"
        transform={transform}
        {...rest}
      >
        {children}
      </TooltipText>
    </g>
  );
}
