import React, { useCallback, useMemo, useState } from 'react';

import { Legend } from './Legend';
import { getGroupValue } from './helpers/getGroupValue';
import { getColorScale } from './helpers/getColorScale';
import { Groups } from './Groups';
import { Ribbons } from './Ribbons';
import { Tooltips } from './Tooltips';

import useMedia from '../../hooks/useMedia';
import { Tooltip } from './Tooltip';

function DataGroup(props) {
  const {
    activeStep,
    activeChords,
    activeGroups,
    groups,
    ribbons,
    matrix,
    metadata,
    width,
    height,
    hasReversedColors = false,
    hasDuration,
    legendLabel,
  } = props;

  const isLargeScreen = useMedia('(min-width: 45em)');

  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [hasTouchLabel, setHasTouchLabel] = useState(false);

  const ribbonFill = useMemo(
    () => getColorScale(matrix.flat(), (d) => d, hasReversedColors),
    [matrix, hasReversedColors]
  );

  const groupFill = useMemo(
    () => getColorScale(groups, getGroupValue, hasReversedColors),
    [groups, hasReversedColors]
  );

  const innerRadius = useMemo(() => {
    if (isLargeScreen)
      return Math.round(Math.min(width * 0.75, height) * 0.6) / 2;
    return Math.round((Math.min(width, height) * 0.6) / 2);
  }, [width, height, isLargeScreen]);

  const outerRadius = useMemo(
    () => Math.round(isLargeScreen ? innerRadius * 1.05 : innerRadius + 30),
    [innerRadius, isLargeScreen]
  );

  function getTargetNode(root, node) {
    try {
      if (root && node) {
        while (!('zone' in node.dataset) && node !== root) {
          node = node.parentNode;
        }

        return node;
      }
    } catch (err) {
      /* ignore errors */
    }
  }

  const setGroup = useCallback(
    function setGroup(node) {
      if (node) {
        const group = parseInt(node.dataset.zone, 10);

        if (activeGroups[0] !== '*') {
          if (!activeGroups.includes(node.dataset.zone)) {
            return;
          }
        }

        if (!isNaN(group)) {
          setSelectedGroup(group);
        }
      }
    },
    [activeGroups]
  );

  const handleGroupEnter = (event) => {
    const { currentTarget, target } = event;
    const node = getTargetNode(currentTarget, target);
    setGroup(node);
  };

  const handleClick = (event) => {
    const { currentTarget, target } = event;
    const node = getTargetNode(currentTarget, target);
    const group = parseInt(node.dataset.zone, 10);

    if (selectedGroup === group) {
      return setSelectedGroup(null);
    }

    if (activeGroups[0] !== '*') {
      if (!activeGroups.includes(node.dataset.zone)) {
        return;
      }
    }

    setSelectedGroup(group);
  };

  const handleTouchStart = () => setHasTouchLabel(true);

  const handleTouchMove = (event) => {
    const { currentTarget } = event;

    // only handle touchmove events triggered by a single touch pointer (finger/stylus/whatever)
    if (event.touches.length === 1) {
      const { clientX, clientY } = event.touches[0];
      const el = document.elementFromPoint(clientX, clientY);
      const node = getTargetNode(currentTarget, el);

      setGroup(node);
    }
  };

  const tooltipText = useMemo(() => {
    if (hasTouchLabel && selectedGroup !== null) {
      let zone = metadata.find((d) => d.id === selectedGroup);
      if (!zone) return null;
      return `${hasDuration ? 'Reistijd' : 'Reizigers'} uit ${zone.name}`;
    }

    return null;
  }, [hasDuration, hasTouchLabel, selectedGroup, metadata]);

  return (
    <>
      <g
        transform={`translate(${
          isLargeScreen ? Math.round((width * 0.73) / 2) : Math.round(width / 2)
        } ${Math.round(height / 2)})`}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onMouseMove={handleGroupEnter}
        onMouseLeave={() => setSelectedGroup(null)}
      >
        <Groups
          activeGroups={activeGroups}
          activeStep={activeStep}
          fill={groupFill}
          groups={groups}
          selectedGroup={selectedGroup}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          metadata={metadata}
          hasLabels={isLargeScreen}
        />

        <Ribbons
          activeChords={activeChords}
          activeStep={activeStep}
          selectedGroup={selectedGroup}
          fill={ribbonFill}
          innerRadius={innerRadius}
          ribbons={ribbons}
        />

        <Tooltips
          activeStep={activeStep}
          selectedGroup={selectedGroup}
          groups={groups}
          ribbons={ribbons}
          metadata={metadata}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          showTouchLabel={hasTouchLabel}
          width={width}
          height={height}
          hasDuration={hasDuration}
        />
      </g>

      {tooltipText && (
        <Tooltip anchor="start" fontSize={'1em'} fontWeight={600} x={24} y={48}>
          {tooltipText}
        </Tooltip>
      )}

      {activeStep === 'change' && (
        <>
          <Legend
            type={'color'}
            id="chord-legend"
            scale={ribbonFill}
            label={legendLabel}
            position={`20,${height - 20 - 54}`}
          />
        </>
      )}
    </>
  );
}

export default DataGroup;
