import React, { useState, useEffect, useRef } from 'react';

import ResponsiveChart from '../data-visualisation/ResponsiveChart';
import DataGroup from './DataGroup';

export default function ChordDiagram(props) {
  const {
    metadata,
    matrix,
    activeStep,
    activeGroups,
    activeChords,
    isVisible,
    ...rest
  } = props;

  const [ribbons, setRibbons] = useState([]);
  const [groups, setGroups] = useState([]);

  const worker = useRef();

  useEffect(() => {
    worker.current = new Worker(
      new URL('./workers/layout.worker.js', import.meta.url),
      {
        type: 'module',
      }
    );

    worker.current.addEventListener('message', (event) => {
      const { ribbons, groups } = event.data;

      setRibbons(ribbons);
      setGroups(groups);
    });

    worker.current.postMessage({
      activeStep,
      matrix,
      metadata,
    });

    return () => worker.current.terminate();
  }, [setRibbons, setGroups, activeStep, matrix, metadata]);

  return (
    <ResponsiveChart>
      {({ width, height }) =>
        isVisible ? (
          <DataGroup
            width={width}
            height={height}
            ribbons={ribbons}
            groups={groups}
            matrix={matrix}
            metadata={metadata}
            activeStep={activeStep}
            activeChords={activeChords}
            activeGroups={activeGroups}
            {...rest}
          />
        ) : null
      }
    </ResponsiveChart>
  );
}
