import React, { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { modularScale } from '../helpers/modular-scale';

const StepCounterContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0 0.25em;
`;

const StepOf = styled('span')`
  flex: 0 100%;
  font-size: ${modularScale(-1)};
  font-family: var(--roboto-condensed);
  line-height: 1;
  color: black;
  margin-left: 0.25em;
`;

export const StepCounter = ({ steps, activeStep }) => {
  const root = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const el = root.current;

    if (el) {
      const dimensions = el.getBoundingClientRect();
      const { width } = dimensions;
      setWidth(width);
    }
  }, [setWidth]);

  const stepWidth = useMemo(() => {
    return Math.max(
      16,
      Math.round((width - 4 * (steps.length - 1)) / steps.length)
    );
  }, [width, steps]);

  const activeStepIndex = useMemo(
    () => steps.findIndex((step) => step === activeStep),
    [steps, activeStep]
  );

  return (
    <StepCounterContainer ref={root}>
      <StepOf>
        {steps.findIndex((s) => s === activeStep) + 1}/{steps.length}
      </StepOf>
      <svg width={width} height={12}>
        {steps.map((step, idx) => (
          <rect
            key={`step-${step}`}
            x={idx > 0 ? idx * (stepWidth + 4) : 0}
            width={stepWidth}
            height={1}
            y={6}
            stroke={idx <= activeStepIndex ? 'black' : 'lightgrey'}
            strokeWidth={1}
          />
        ))}
      </svg>
    </StepCounterContainer>
  );
};
