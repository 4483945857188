import styled from 'styled-components/macro';
import { modularScale } from '../../helpers/modular-scale';

export const SubChapterTitle = styled('h3')`
  font-family: var(--harmonia);
  font-size: ${modularScale(2)};
  margin: 1.5rem 0;
  line-height: 1.2;
  text-align: center;

  @media screen and (min-width: 45em) {
    font-size: ${modularScale(3)};
  }
`;
