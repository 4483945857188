import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ChapterIntro } from '../components/simple/ChapterIntroduction';
import Section from '../components/simple/Section';
import { useScrollToTop } from '../hooks/useScrollToTop';
import metroStationDePijp from '../images/metrostation-de-pijp.jpg';

export default function Network(props) {
  const location = useLocation();
  useScrollToTop(location.pathname);

  return (
    <Section>
      <ChapterIntro>
        <ChapterIntro.Grid>
          <ChapterIntro.Heading>
            Verandering in het openbaar vervoer
          </ChapterIntro.Heading>

          <ChapterIntro.Image
            src={metroStationDePijp}
            alt="Metrotunnel in aanbouw, gezien vanaf metrostation De Pijp"
          />

          <ChapterIntro.Text>
            In dit hoofdstuk is te zien wat er veranderd is in het Amsterdamse
            openbaar vervoer en de impact daarvan op de dienstregeling.
          </ChapterIntro.Text>
        </ChapterIntro.Grid>
      </ChapterIntro>

      {props.children}
    </Section>
  );
}
