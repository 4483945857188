import React, { useMemo, useEffect, useState, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';

import { SVG, Root } from '../data-visualisation/ResponsiveChart';
import { Spinner } from '../Spinner';
import { DataGroup } from './DataGroup';
import { basePath, colors } from '../../constants';

import useMedia from '../../hooks/useMedia';
import useDimensions from '../../hooks/useDimensions';
import useGeo from '../../hooks/useGeo';

const padding = {
  top: 0,
  right: 0.27,
  left: 0,
  bottom: 0,
};

function getColors(state) {
  switch (state) {
    case 'before':
    case 'after':
      return colors.sequential;
    case 'change':
      return colors.diverging;
    default:
      return colors.sequential;
  }
}

function RideFrequencyData(props) {
  const { activeDataLayer } = props;
  const [dataState, visualizationType] = activeDataLayer;

  const isLargeScreen = useMedia('(min-width: 45em)');

  const { data: before, error: beforeError } = useSWRImmutable(
    `${basePath}/ride-frequency/20170912-ride-frequency.geojson`
  );

  const { data: after, error: afterError } = useSWRImmutable(
    `${basePath}/ride-frequency/20180904-ride-frequency.geojson`
  );

  const beforeLoading = !before && !beforeError;
  const afterLoading = !after && !afterError;
  const isLoading = beforeLoading || afterLoading;

  const [isWorking, setIsWorking] = useState(true);

  const [layers, setLayers] = useState({
    before: [],
    after: [],
    change: [],
  });

  const [ref, dimensions] = useDimensions();

  const worker = useRef();

  useEffect(() => {
    worker.current = new Worker(
      new URL('./layers.worker.js', import.meta.url),
      { type: 'module' }
    );

    worker.current.addEventListener('message', (event) => {
      const { before, after, change } = event.data;
      setLayers({ before, after, change });
      setIsWorking(false);
    });

    return () => worker.current.terminate();
  }, []);

  useEffect(() => {
    if (
      Boolean(before) &&
      Boolean(after) &&
      dimensions.width &&
      dimensions.height
    ) {
      worker.current.postMessage({
        beforeData: before,
        afterData: after,
        height: dimensions.height,
        width: dimensions.width,
        config: {
          map: { padding },
          distribution: {
            padding: isLargeScreen ? 5 : 3,
            range: isLargeScreen
              ? [64, dimensions.width - (dimensions.width * 0.27 + 64 * 2)]
              : [16, dimensions.width - 64],
          },
        },
      });
      setIsWorking(true);
    }
  }, [before, after, dimensions, isLargeScreen]);

  const colors = useMemo(() => getColors(dataState), [dataState]);

  const generators = useGeo(
    dimensions.width,
    dimensions.height,
    before,
    padding
  );

  return (
    <Root ref={ref}>
      {(isLoading || isWorking) && <Spinner />}
      {!(isLoading || isWorking) && (
        <SVG {...dimensions}>
          <DataGroup
            extent={before}
            data={layers[dataState]}
            dataState={dataState}
            type={visualizationType}
            colors={colors}
            {...generators}
            {...dimensions}
          />
        </SVG>
      )}
    </Root>
  );
}

export default RideFrequencyData;
