import { geoMercator, geoPath } from 'd3-geo';
import { featureCollection } from '@turf/helpers';
import { useMemo } from 'react';

export default useGeo;

// default padding
const defaultPadding = 20;

/**
 * This hook creates d3 map generator functions for all your maps.
 * Note: padding is expressed as a ratio (between 0 - 1) of height/width.
 */
function useGeo(
  width = 0,
  height = 0,
  extent = featureCollection([]),
  padding
) {
  const projection = useMemo(
    () => getProjection(width, height, extent, padding),
    [extent, height, padding, width]
  );

  const path = useMemo(() => geoPath(projection), [projection]);

  return { projection, path };
}

export function getProjection(
  width,
  height,
  extent,
  padding = { top: 0, right: 0, bottom: 0, left: 0 }
) {
  const x0 = Math.round(Math.max(width * padding.left, defaultPadding));
  const y0 = Math.round(Math.max(height * padding.top, defaultPadding));
  const x1 =
    width - Math.round(Math.max(width * padding.right, defaultPadding));
  const y1 =
    height - Math.round(Math.max(height * padding.bottom, defaultPadding));

  const projection = geoMercator()
    .fitExtent(
      [
        [x0, y0],
        [x1, y1],
      ],
      extent
    )
    .clipExtent([
      [0.05, 0.05],
      [width, height],
    ]);

  return projection;
}
