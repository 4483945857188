import styled from 'styled-components/macro';

export const SubChapterIntro = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 3em 0;
  padding: 0 0.75rem;

  @media screen and (min-width: 45em) {
    margin: 5em 0;
  }
`;
