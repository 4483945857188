import { extent, bin } from 'd3-array';
import { scalePow, scaleQuantize } from 'd3-scale';

import * as React from 'react';
import { useMemo, useState } from 'react';

import useMedia from '../../hooks/useMedia';

import TileLayer from '../data-visualisation/TileLayer';
import { OpacityTransitionGroup } from '../data-visualisation/OpacityTransitionGroup';
import { Axis } from '../data-visualisation/Axis';
import Annotations from './Annotations';
import { Tooltip } from './Tooltip';
import { Legend } from '../data-visualisation/Legend';
import NodeLayer from './NodeLayer';
import Cells from './Cells';

export const round = (x) => Math.round(x);
const getFrequency = (d) => d.frequency;

export function DataGroup(props) {
  const { projection, colors, data, dataState, width, height, type } = props;

  const isLargeScreen = useMedia('(min-width: 45em)');

  const [activeNode, setActiveNode] = useState();

  const x = scalePow()
    .exponent(0.3)
    .range(
      isLargeScreen ? [64, width - (width * 0.27 + 64 * 2)] : [16, width - 64]
    )
    .domain(extent(data.map(getFrequency)))
    .nice();

  const binX = useMemo(() => {
    const generator = bin().value(getFrequency);
    const bins = generator(data).map((bin) => bin.x0);
    return scaleQuantize().domain(x.domain()).range(bins);
  }, [data, x]);

  const color = useMemo(() => {
    if (!binX) return null;
    return binX.copy().range(colors);
  }, [binX, colors]);

  const handleNodeInteraction = (event) => {
    if (event.type === 'mouseout') {
      return setActiveNode(null);
    }

    const { node: id } = event.target.dataset;
    const node = data.find((node) => node.id === id);

    setActiveNode(node);
  };

  return (
    <>
      <rect
        x={0}
        y={0}
        width={'100%'}
        height={'100%'}
        style={{ fill: 'var(--light-gray)' }}
      />

      <OpacityTransitionGroup
        show={type === 'map'}
        startOpacity={0.2}
        endOpacity={1}
      >
        <TileLayer projection={projection} width={width} height={height} />
      </OpacityTransitionGroup>

      {data && (
        <NodeLayer
          colorScale={color}
          nodeRadius={isLargeScreen ? 4 : 2}
          nodes={data}
          type={type}
        />
      )}

      {activeNode && (
        <circle
          cx={activeNode[type].x}
          cy={activeNode[type].y}
          fill={color(activeNode.frequency)}
          r={isLargeScreen ? 4 : 2}
          stroke="black"
          strokeWidth="1"
        />
      )}

      {data.length > 0 && (
        <OpacityTransitionGroup show={type === 'distribution'}>
          <Axis
            x={x}
            binX={binX}
            width={width}
            height={height}
            type={dataState}
            sampleCount={isLargeScreen ? 5 : 2}
            {...props}
          />
        </OpacityTransitionGroup>
      )}

      <OpacityTransitionGroup
        show={type === 'distribution' && dataState === 'change'}
      >
        <Annotations x={x} height={height} />
      </OpacityTransitionGroup>

      {activeNode && (
        <>
          <circle
            cx={activeNode.x}
            cy={activeNode.y}
            fill={color(activeNode.frequency)}
            r={isLargeScreen ? 4 : 2}
            stroke="black"
            strokeWidth="1"
          />
          <g className="tooltips">
            <Tooltip
              key={`tooltip-${activeNode.id}`}
              node={activeNode}
              type={type}
              state={dataState}
              width={width}
              height={height}
            />
          </g>
        </>
      )}

      <Legend
        position={`20,${height - 20 - 60}`}
        scale={color}
        type={dataState}
        range={colors}
        label={'ritten per dag'}
      />

      <Cells
        nodes={data}
        type={type}
        width={width}
        height={height}
        handleNodeInteraction={handleNodeInteraction}
      />
    </>
  );
}
