import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';
import ChangedLines from './chapters/ChangedLines';
import Commuters from './chapters/Commuters';
import Footer from './chapters/Footer';
import Introduction from './chapters/Introduction';
import Network from './chapters/Network';
import OriginDestination from './chapters/OriginDestination';
import OriginDestinationTime from './chapters/OriginDestinationTime';
import PossibleTrips from './chapters/PossibleTrips';
import Recap from './chapters/Recap';
import RideFrequency from './chapters/RideFrequency';
import Transfers from './chapters/Transfers';
import AnimatedSwitch from './components/AnimatedSwitch';
import GlobalStyles from './components/GlobalStyles';
import { Navigation } from './components/navigation/navigation';

function App() {
  return (
    <SWRConfig
      value={{
        fetcher: (...args) => fetch(...args).then((res) => res.json()),
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <GlobalStyles />

      <BrowserRouter>
        <AnimatedSwitch>
          <Route exact path="/">
            <Introduction />
          </Route>

          <Route path="/verandering-in-het-openbaar-vervoer">
            <article>
              <Network>
                <ChangedLines />
                <RideFrequency />
              </Network>
            </article>
          </Route>

          <Route path="/verandering-voor-de-reiziger">
            <article>
              <Commuters>
                <OriginDestination />
                <OriginDestinationTime />
                <Transfers />
              </Commuters>
            </article>
          </Route>

          <Route path="/impact-van-de-noord-zuid-lijn">
            <Recap>
              <PossibleTrips />
            </Recap>
            <Navigation />
            <Footer />
          </Route>
        </AnimatedSwitch>

        <Route
          exact
          path={[
            '/',
            '/verandering-in-het-openbaar-vervoer',
            '/verandering-voor-de-reiziger',
          ]}
        >
          <Navigation />
        </Route>
      </BrowserRouter>
    </SWRConfig>
  );
}

export default App;
