import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components/macro';
import { lineString } from '@turf/helpers';
import { scaleLinear } from 'd3-scale';
import useSWRImmutable from 'swr/immutable';

import ResponsiveChart from '../data-visualisation/ResponsiveChart';
import MapBaseGroup from '../data-visualisation/MapBaseGroup';
import { modularScale } from '../../helpers/modular-scale';
import { interpolatePath } from 'd3-interpolate-path';
import TileLayer from '../data-visualisation/TileLayer';
import { OpacityTransitionGroup } from '../data-visualisation/OpacityTransitionGroup';
import { basePath } from '../../constants';

const ChartContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
`;

const StopName = styled('text')`
  fill: white;
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  text-anchor: end;
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

const StopCircle = styled('circle')`
  fill: rgb(236, 0, 1);
  stroke: white;
  stroke-width: 4;
`;

const Stop = React.memo(({ x, y, name }) => (
  <g>
    <StopCircle cx={x} cy={y} r="6" />
    <StopName x={x} y={y} dx={-14} dy={5}>
      {name}
    </StopName>
  </g>
));

function NoordZuidLijn(props) {
  const { interpolator, progress } = props;

  const clamp = (range) => scaleLinear().domain([0, 1]).range(range);
  const opacity = clamp([1, 0.8]);
  const width = clamp([10, 5]);

  return (
    <path
      fill="none"
      shapeRendering="geometricPrecision"
      stroke="white"
      strokeOpacity={opacity(progress)}
      strokeWidth={width(progress)}
      d={interpolator(progress)}
    />
  );
}

function IntroductionMap(props) {
  const { progress } = props;

  const { data: nzlData, error: nzlError } = useSWRImmutable(
    `${basePath}/nzl.geojson`
  );

  const { data: nzlStopsData, error: nzlStopsError } = useSWRImmutable(
    `${basePath}/nzl-stops.geojson`
  );

  const nzlLoading = !nzlData && !nzlError;
  const nzlStopsLoading = !nzlStopsData && !nzlStopsError;
  const loading = nzlLoading || nzlStopsLoading;

  const getInterpolator = useCallback(
    function getInterpolator({ path, projection, width, height }) {
      if (nzlData) {
        const start = path(
          lineString([
            projection.invert([0, height / 2]),
            projection.invert([width, height / 2]),
          ])
        );

        const end = path(nzlData);

        return interpolatePath(start, end);
      }

      return () => '';
    },
    [nzlData]
  );

  if (loading) return null;

  return (
    <ChartContainer>
      <ResponsiveChart>
        {({ width, height }) => (
          <MapBaseGroup
            width={width}
            height={height}
            extent={nzlData}
            topPadding={0.3}
            bottomPadding={100}
          >
            {({ path, projection }) => {
              let interpolator = getInterpolator({
                path,
                projection,
                width,
                height,
              });

              return (
                <Fragment>
                  <OpacityTransitionGroup show={progress > 0.3}>
                    <TileLayer
                      width={width}
                      height={height}
                      path={path}
                      projection={projection}
                      redMap
                    />
                  </OpacityTransitionGroup>

                  <NoordZuidLijn
                    interpolator={interpolator}
                    progress={progress}
                  />

                  <OpacityTransitionGroup
                    show={progress > 0.95}
                    startOpacity={0}
                    endOpacity={progress}
                  >
                    {nzlStopsData.features.map((d) => {
                      const [x, y] = projection(d.geometry.coordinates);

                      return (
                        <Stop
                          key={d.properties.id}
                          x={x}
                          y={y}
                          {...d.properties}
                        />
                      );
                    })}
                  </OpacityTransitionGroup>
                </Fragment>
              );
            }}
          </MapBaseGroup>
        )}
      </ResponsiveChart>
    </ChartContainer>
  );
}

export default IntroductionMap;
