import styled from 'styled-components/macro';
import modularScale from '../../helpers/modular-scale';
import { Stripe } from '../../styles/Stripe';

const ChapterIntro = styled('header')`
  background: var(--light-gray);
`;

const Grid = styled.div`
  --stripe-size: 4rem;

  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(16, 1fr);
  min-height: calc(100vh - (var(--stripe-size) + 1.5rem) * 2);

  position: relative;
  padding: 0 0.75rem;
  margin: calc(var(--stripe-size) + 1.5rem) 0;

  &::before,
  &::after {
    ${Stripe};
    height: var(--stripe-size);
  }

  &::before {
    top: calc(-1 * var(--stripe-size));
  }

  &::after {
    bottom: calc(-1 * var(--stripe-size));
  }

  @media screen and (min-width: 32.5em) {
    padding: 2.5rem;
  }
`;

const Heading = styled.h1`
  margin: 1em 0;
  z-index: 3;

  grid-column: 1 / 17;
  grid-row: 1;

  font-family: var(--harmonia);
  font-size: ${modularScale(3)};
  color: var(--red);
  line-height: 1.2;

  @media screen and (min-width: 45em) {
    font-size: ${modularScale(5)};
    grid-column: 7 / 17;
    max-width: 15ch;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  max-height: 100%;

  grid-row: 1 / 3;
  grid-column: 7 / 17;

  z-index: 1;

  object-fit: contain;
  filter: grayscale(1);

  @media screen and (min-width: 45em) {
    grid-column: 2 / 9;
  }
`;

const Text = styled.p`
  grid-row: 3;
  grid-column: 2 / 16;
  z-index: 2;
  margin: 0;
  font-size: ${modularScale(0)};
  max-width: unset;

  @media screen and (min-width: 55em) {
    grid-column: 10 / 17;
    grid-row: 2 / 3;
  }

  @media screen and (min-width: 65em) {
    font-size: ${modularScale(1)};
  }
`;

ChapterIntro.Grid = Grid;
ChapterIntro.Heading = Heading;
ChapterIntro.Text = Text;
ChapterIntro.Image = Image;

export { ChapterIntro };
