import * as React from 'react';
import styled from 'styled-components/macro';
import CFLogo from '../images/cf-logo.svg';
import AMSLogo from '../images/ams-logo.png';
import TUDelftLogo from '../images/tu-delft-logo.svg';
import GVBLogo from '../images/gvb-logo.svg';
import OpenGeoLogo from '../images/opengeo-logo.png';

import Section from '../components/simple/Section';

const StyledSection = styled(Section)`
  color: white;
  flex-flow: row wrap;
  justify-content: center;
  background-color: black;
  padding-top: 3rem;
`;

const Column = styled.div`
  flex: 1;
  min-width: 20rem;
  margin-bottom: 3rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: white;
  }
`;

const ImagesWrapper = styled.div`
  padding: 1.5em;
  min-width: 20rem;
`;

const Images = styled('div')`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  max-height: 15em;
  margin: -0.75em;

  @media screen and (min-width: 45em) {
    align-items: center;
  }

  > * {
    margin: 0.75em 0;
    flex: 1;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 7rem;
    object-fit: contain;
    filter: brightness(0) invert(1);
  }
`;

export default Footer;

function Footer() {
  return (
    <StyledSection as="footer">
      <Column>
        <p>
          Deze website visualiseert de onderzoeksresultaten van ‘Monitoring the
          North-Southline’, een grootschalig meerjarig onderzoek naar de impact
          van de Noord/Zuidlijn op de grootstedelijke regio Amsterdam.
        </p>

        <p>
          Dit onderzoek is een samenwerking tussen{' '}
          <a
            href="https://www.ams-institute.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amsterdam Institute for Advanced Metropolitan Studies
          </a>{' '}
          en{' '}
          <a
            href="https://www.tudelft.nl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Technische Universiteit Delft
          </a>
          . Ontwerp en realisatie van de visualisaties is gedaan door{' '}
          <a
            href="https://www.cleverfranke.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CLEVER°FRANKE
          </a>
          .
        </p>
      </Column>

      <Column>
        <ImagesWrapper>
          <Images>
            <a
              href="https://www.ams-institute.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ flexBasis: '50%' }}
            >
              <img src={AMSLogo} alt="AMS Institute" />
            </a>

            <a
              href="https://cleverfranke.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CFLogo} alt="CLEVER°FRANKE" />
            </a>

            <a
              href="https://www.tudelft.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TUDelftLogo} alt="TU Delft" />
            </a>

            <a
              href="https://www.gvb.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GVBLogo} alt="GVB" style={{ height: '3em' }} />
            </a>

            <a
              href="https://opengeo.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={OpenGeoLogo} alt="Stichting Opengeo" />
            </a>
          </Images>
        </ImagesWrapper>
      </Column>
    </StyledSection>
  );
}
