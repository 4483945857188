import * as React from 'react';
import styled from 'styled-components/macro';
import { modularScale } from '../../helpers/modular-scale';

export const TooltipText = styled('text')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

export function Tooltip({ node, state, type, width }) {
  if (node === undefined || node === null) return null;

  const { id, name, frequency } = node;
  const { x, y } = node[type];

  const onLeft = x < width / 2;

  return (
    <g shapeRendering="geometricPrecision">
      <defs>
        <filter
          x="-0.05"
          y="-0.1"
          width="110%"
          height="120%"
          id={`background-${id}`}
        >
          <feFlood floodColor="white" floodOpacity={0.8} />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>

      <TooltipText
        x={Math.round(x)}
        y={Math.round(y)}
        dx={onLeft ? 12 : -12}
        dy={6}
        textAnchor={onLeft ? 'start' : 'end'}
        filter={`url(#background-${id})`}
        fill="black"
      >
        <tspan fontWeight="bold">{name}</tspan>
        <tspan x={Math.round(x)} dx={onLeft ? 12 : -12} dy="16">
          {state === 'change' && frequency >= 0 && '+'}
          {frequency} ritten/dag
        </tspan>
      </TooltipText>
    </g>
  );
}
