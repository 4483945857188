import { getCoord } from '@turf/invariant';
import React from 'react';
import styled from 'styled-components';
import useSwrImmutable from 'swr/immutable';
import MapBaseGroup from '../components/data-visualisation/MapBaseGroup';
import ResponsiveChart from '../components/data-visualisation/ResponsiveChart';
import TileLayer from '../components/data-visualisation/TileLayer';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { Spinner } from '../components/Spinner';
import { modularScale } from '../helpers/modular-scale';

const LargeKPI = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin: -0.5rem;
  > * {
    margin: 0.5rem;
  }
`;

const KPI = styled('span')`
  font-size: ${modularScale(2)};
  font-family: var(--harmonia);
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.1em;
  color: ${({ color }) => color};
`;

const Label = styled('span')`
  font-size: ${modularScale(0)};
  font-weight: bold;
  font-family: var(--harmonia);
  letter-spacing: 0.02em;
`;

const StopName = styled('text')`
  fill: white;
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  text-anchor: end;
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

const StopCircle = styled('circle')`
  fill: var(--red);
  stroke: white;
  stroke-width: 4;
`;

const Stop = ({ x, y, name }) => (
  <g>
    <StopCircle cx={x} cy={y} r="6" />
    <StopName x={x} y={y} dx={-14} dy={5}>
      {name}
    </StopName>
  </g>
);

const NZLMap = ({ nzl, nzlStops }) => {
  return (
    <ResponsiveChart>
      {(dimensions) => (
        <MapBaseGroup
          topPadding={0.1}
          bottomPadding={100}
          {...dimensions}
          extent={nzl}
        >
          {(generators) => (
            <>
              <TileLayer redMap {...dimensions} {...generators} />
              <path
                fill={'none'}
                stroke={'#fff'}
                strokeWidth={5}
                d={generators.path(nzl)}
              />
              {nzlStops.features.map((d) => {
                const [x, y] = generators.projection(getCoord(d));

                return (
                  <Stop key={d.properties.id} x={x} y={y} {...d.properties} />
                );
              })}
            </>
          )}
        </MapBaseGroup>
      )}
    </ResponsiveChart>
  );
};

export function NoordZuidLijn(props) {
  const basePath = `${process.env.PUBLIC_URL}/data`;
  const { data: nzlData } = useSwrImmutable(`${basePath}/nzl.geojson`);
  const { data: nzlStopsData } = useSwrImmutable(
    `${basePath}/nzl-stops.geojson`
  );

  return (
    <SideScrollingGrid>
      <SideScrollingGrid.Content>
        {nzlData && nzlStopsData ? (
          <NZLMap
            isVisible={props.isVisible}
            nzl={nzlData}
            nzlStops={nzlStopsData}
          />
        ) : (
          <Spinner />
        )}
      </SideScrollingGrid.Content>
      <SideScrollingGrid.SideStory>
        <Step>
          <LargeKPI>
            <KPI color={'#ec0001'}>105.000</KPI>
            <Label>reizigers</Label>
          </LargeKPI>
          <p>
            In 2019 gebruiken gemiddeld 105.000 reizigers per werkdag de
            Noord/Zuidlijn. De Noord/Zuid-lijn vervoert daarmee ongeveer een
            kwart van het totaal aantal metroreizigers in Amsterdam.
          </p>
          <p>
            Hier is duidelijk zichtbaar dat de plannen van het GVB om de metro
            te transformeren tot de ruggengraat van het OV van Amsterdam werken.
          </p>
        </Step>
        <Step>
          <LargeKPI>
            <KPI color={'#ec0001'}>7%</KPI>
            <Label>minder bussen en trams op straat</Label>
          </LargeKPI>
          <p>
            Tussen najaar 2017 en najaar 2018 zijn minder reizigers de tram (14%
            minder) en de bus (15% minder) gaan gebruiken, ten opzichte van de
            metro, waarvan het gebruik groeit met 36% in dezelfde periode.
            Daardoor kon het aantal trams en bussen op straat omlaag met 7%.
            Toch groeide het aantal reizen in het netwerk met 4%.
          </p>
        </Step>
        <Step>
          <LargeKPI>
            <KPI color={'#ec0001'}>43</KPI>
            <Label>seconden</Label>
          </LargeKPI>
          <p>
            Door de Noord/Zuidlijn doort een gemiddelde reis in het GVB-netwerk
            43 seconden korten. Op een gemiddelde werkdag wordt daardoor 7.500
            uur bespaard.
          </p>
        </Step>
      </SideScrollingGrid.SideStory>
    </SideScrollingGrid>
  );
}

export default NoordZuidLijn;
