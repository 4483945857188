import * as React from 'react';
import { interpolate, quantize } from 'd3-interpolate';
import styled from 'styled-components/macro';
import { modularScale } from '../../helpers/modular-scale';

const AnnotationLine = styled('line')`
  stroke: black;
  stroke-width: 1;
  fill: none;
`;

const Label = styled('text')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  letter-spacing: 0.01em;
  text-transform: uppercase;
`;

export default React.memo(function Annotations(props) {
  const { x, height } = props;

  const [start, end] = x.domain();
  let interpolator = interpolate(...x.domain());
  let samples = quantize(interpolator, 7);

  return (
    <g className="annotations">
      <g transform={`translate(0, ${height * 0.35})`}>
        <AnnotationLine x1={x(start)} x2={x(start)} y1={24} y2={30} />
        <AnnotationLine x1={x(samples[2])} x2={x(samples[2])} y1={24} y2={30} />
        <AnnotationLine x1={x(start)} x2={x(samples[2])} y1={24} y2={24} />

        <Label y={1} textAnchor={'start'}>
          <tspan x={x(start)} y={0}>
            Meer haltes met
          </tspan>
          <tspan x={x(start)} y={16}>
            afname in ritten
          </tspan>
        </Label>
      </g>

      <g transform={`translate(0, ${height * 0.35})`}>
        <AnnotationLine
          x1={x(samples[samples.length - 3])}
          x2={x(samples[samples.length - 3])}
          y1={24}
          y2={30}
        />
        <AnnotationLine x1={x(end)} x2={x(end)} y1={24} y2={30} />
        <AnnotationLine
          x1={x(end)}
          x2={x(samples[samples.length - 3])}
          y1={24}
          y2={24}
        />

        <Label y={1} textAnchor={'end'}>
          <tspan x={x(end)} y={0}>
            Minder haltes met
          </tspan>
          <tspan x={x(end)} y={16}>
            toename in ritten
          </tspan>
        </Label>
      </g>
    </g>
  );
});
