import React, { useMemo, useEffect, useRef, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { parseCSV } from '../../helpers/parse-csv';
import { Spinner } from '../Spinner';
import ChordDiagram from './ChordDiagram';
import { basePath } from '../../constants';

const fetcher = (url) => fetch(url).then(parseCSV);

function OriginDestinationData(props) {
  const { activeStep, dataAccessor, ...rest } = props;

  const { data: zonesData, error: zonesError } = useSWRImmutable(
    `${basePath}/origin-destination/zones.json`
  );

  const { data: beforeData, error: beforeError } = useSWRImmutable(
    `${basePath}/origin-destination/20170911-20171015.csv`,
    {
      fetcher,
    }
  );

  const { data: afterData, error: afterError } = useSWRImmutable(
    `${basePath}/origin-destination/20180910-20181021.csv`,
    {
      fetcher,
    }
  );

  const zonesLoading = !zonesData && !zonesError;
  const beforeLoading = !beforeData && !beforeError;
  const afterLoading = !afterData && !afterError;
  const loading = zonesLoading || beforeLoading || afterLoading;

  const [metadata, setMetadata] = useState([]);
  const [before, setBefore] = useState([]);
  const [after, setAfter] = useState([]);
  const [change, setChange] = useState([]);

  const worker = useRef();

  useEffect(() => {
    worker.current = new Worker(
      new URL('./workers/calculateMatrices.worker.js', import.meta.url),
      {
        type: 'module',
      }
    );

    worker.current.addEventListener('message', (event) => {
      const { metadata, before, after, change } = event.data;

      setMetadata(metadata);
      setBefore(before);
      setAfter(after);
      setChange(change);
    });

    return () => worker.current.terminate();
  }, []);

  useEffect(() => {
    if (!loading) {
      worker.current.postMessage({
        beforeLinks: beforeData,
        afterLinks: afterData,
        nodes: zonesData,
        accessor: dataAccessor,
      });
    }
  }, [afterData, beforeData, dataAccessor, loading, zonesData]);

  const matrix = useMemo(() => {
    const options = { before, after, change };
    return options[activeStep];
  }, [activeStep, before, after, change]);

  if (loading) return <Spinner />;

  return (
    <ChordDiagram
      activeStep={activeStep}
      matrix={matrix}
      metadata={metadata}
      {...rest}
    />
  );
}

export default OriginDestinationData;
