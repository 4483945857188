import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { produce } from 'immer';

import { status as statusColors } from '../../constants';
import TileLayer from '../data-visualisation/TileLayer';
import RouteLayer from '../data-visualisation/RouteLayer';
import ResponsiveChart from '../data-visualisation/ResponsiveChart';
import MapBaseGroup from '../data-visualisation/MapBaseGroup';
import { ChangedLinesContext } from '../../chapters/ChangedLines';
import useMedia from '../../hooks/useMedia';

const Route = styled('path')`
  fill: none;
  transition: opacity 120ms ease;
`;

const Bus = styled(Route)`
  stroke-width: 1;
`;

const Tram = styled(Route)`
  stroke-width: 2;
`;

const Metro = styled(Route)`
  stroke-width: 4;
`;

const modalityRouteComponents = {
  bus: Bus,
  tram: Tram,
  metro: Metro,
};

export function ChangedLinesMap(props) {
  const { activeModality, routes } = props;

  const showTags = useMedia('(min-width: 45em)');

  const ctx = useContext(ChangedLinesContext);
  const { activeStep, colors } = ctx;
  const activeLayers = activeStep.split(',');

  const getRouteComponentByProperty = (property) => (feature) =>
    modalityRouteComponents[feature.properties[property]];

  const getColorByProperty = (property) => (feature) =>
    statusColors[feature.properties[property]];

  const getColorByStep = () => colors[activeStep];

  let activeProperties = {};

  if (activeLayers.length > 0) {
    activeProperties = produce(activeProperties, (draft) => {
      draft['status'] = [...activeLayers];
    });
  }

  if (activeModality !== 'all') {
    activeProperties = produce(activeProperties, (draft) => {
      draft.modality = activeModality;
    });
  }

  return (
    <ResponsiveChart>
      {(dimensions) => (
        <MapBaseGroup {...dimensions} extent={routes} rightPadding={0.15}>
          {(generators) => (
            <>
              <TileLayer {...generators} {...dimensions} />
              <RouteLayer
                {...generators}
                showTags={showTags}
                data={routes}
                activeProperties={activeProperties}
                getRouteComponent={getRouteComponentByProperty('modality')}
                hasStrokeDasharray={
                  activeStep === 'changed-old-state,changed-new-state'
                }
                getStroke={
                  activeStep === 'unchanged,changed-new-state,new'
                    ? getColorByProperty('status')
                    : getColorByStep
                }
              />
            </>
          )}
        </MapBaseGroup>
      )}
    </ResponsiveChart>
  );
}

export default ChangedLinesMap;
