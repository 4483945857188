import { useState, useCallback, useLayoutEffect } from 'react';

export default useDimensions;

const initialState = {
  width: null,
  height: null,
  top: null,
  left: null,
};

// Measures your DOM nodes
function useDimensions() {
  const [dimensions, setDimensions] = useState(initialState);
  const [node, setNode] = useState();

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      let frame = undefined;

      const ro = new ResizeObserver((entries) => {
        frame = window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const { left, top, width, height } = entry.contentRect;
            setDimensions({ left, top, width, height });
          }
        });
      });

      ro.observe(node);

      return () => {
        window.cancelAnimationFrame(frame);
        ro.disconnect();
      };
    }
  }, [node]);

  return [ref, dimensions];
}
