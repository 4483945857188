import styled from 'styled-components/macro';

export const Subtitle = styled('span')`
  margin: 1.5em 0;
  font-size: 0.9em;
  font-family: var(--roboto-condensed);
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--red);
`;
