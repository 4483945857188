import React, { useCallback, useMemo } from 'react';
import { arc as createArcGenerator } from 'd3-shape';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components/macro';

import { modularScale } from '../../helpers/modular-scale';
import { getGroupValue } from './helpers/getGroupValue';

const Zone = styled.text`
  font-size: 0.6em;
  font-family: var(--roboto-condensed);
  transition: transform 200ms linear;

  @media screen and (min-width: 45em) {
    font-size: ${modularScale(-1)};
  }
`;

export function Groups(props) {
  const {
    activeGroups,
    activeStep,
    fill,
    groups,
    selectedGroup,
    innerRadius,
    outerRadius,
    metadata,
    hasLabels,
  } = props;

  const hoverArc = useMemo(
    () =>
      createArcGenerator()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius * 3),
    [innerRadius, outerRadius]
  );

  const basicArc = useMemo(
    () =>
      createArcGenerator().innerRadius(innerRadius).outerRadius(outerRadius),
    [innerRadius, outerRadius]
  );

  const getFill = useCallback(
    (group) => {
      if (activeGroups[0] === '*' || activeGroups.includes(`${group.id}`)) {
        if (activeStep === 'change') {
          return fill(getGroupValue(group));
        }

        return '#ec0001';
      }

      return '#ffffff';
    },
    [activeGroups, activeStep, fill]
  );

  const showLabel = useCallback(
    (group) => {
      if (activeGroups[0] === '*') return true;
      if (activeGroups.includes(`${group.id}`)) return true;
      return false;
    },
    [activeGroups]
  );

  const transition = useTransition(groups, {
    keys: (group) => `zone-node-${group.id}`,
    from: (item) => ({
      d: basicArc(item),
      fill: getFill(item),
    }),
    update: (item) => ({
      d: basicArc(item),
      fill: getFill(item),
    }),
  });

  return (
    <g className="groups">
      {transition((style, item) => (
        <g data-zone={item.id}>
          <animated.path
            d={hoverArc(item)}
            fill="white"
            opacity={selectedGroup === item.id ? 0.8 : 0}
            style={{ transition: 'opacity 200ms ease-in-out' }}
          />

          <animated.path d={style.d} fill={style.fill} />

          {hasLabels && showLabel(item) && (
            <Zone
              dy={4}
              transform={`rotate(${
                (item.textAngle * 180) / Math.PI - 90
              }) translate(${outerRadius + 5}) rotate(${
                item.textAngle > Math.PI ? 180 : 0
              })`}
              textAnchor={item.textAngle > Math.PI ? 'end' : null}
            >
              {metadata[item.index].name}
            </Zone>
          )}
        </g>
      ))}
    </g>
  );
}
