import * as React from 'react';
import { featureCollection } from '@turf/helpers';
import { getCoord } from '@turf/invariant';

import MapBaseGroup from './MapBaseGroup';
import TileLayer from './TileLayer';
import { Stop } from './Stop';

let width = 350;
let height = 260;

const getProperty = (property) => (feature) => feature.properties[property];
const getId = getProperty('id');
const getType = getProperty('type');

const SmallMultipleMap = ({ stops, legs }) => {
  return (
    <svg width={width} height={height}>
      <MapBaseGroup
        extent={featureCollection(stops)}
        width={width}
        height={height}
      >
        {({ path, projection }) => (
          <>
            <TileLayer
              path={path}
              projection={projection}
              width={width}
              height={height}
            />
            {legs.map((leg) => (
              <path
                key={getId(leg)}
                d={path(leg)}
                fill={'transparent'}
                stroke={'var(--red)'}
                strokeWidth={'2'}
              />
            ))}
            {stops.map((stop) => {
              let [x, y] = projection(getCoord(stop));
              return (
                <Stop key={getId(stop)} type={getType(stop)} cx={x} cy={y} />
              );
            })}
          </>
        )}
      </MapBaseGroup>
    </svg>
  );
};

export default SmallMultipleMap;
