import * as React from 'react';
import styled from 'styled-components';
import { ChapterIntro } from '../components/simple/ChapterIntroduction';
import Section from '../components/simple/Section';
import impact from '../images/impact.jpg';

const FinalChapterIntro = styled(ChapterIntro.Grid)`
  background: var(--red);
  border: 2em solid var(--light-gray);
  color: white;
`;

const StyledImage = styled(ChapterIntro.Image)`
  mix-blend-mode: hard-light;
`;

const Heading = styled(ChapterIntro.Heading)`
  color: white;
`;

export default function Recap(props) {
  return (
    <Section>
      <ChapterIntro>
        <FinalChapterIntro>
          <Heading>De impact van de Noord/Zuidlijn</Heading>
          <StyledImage
            src={impact}
            alt="Tram lijn 2 en een vertrektijdenbord op de Dam"
          />
          <ChapterIntro.Text>
            <p>
              De Noord/Zuidlijn heeft veel veranderingen veroorzaakt voor
              reizigers en hoe zij reizen. De socio-economische gevolgen op de
              lange termijn zijn echter zeker zo belangrijk. Wat de
              Noord/Zuidlijn betekent voor Amsterdam moet de komende jaren nog
              blijken. De gevolgen op de korte termijn zijn duidelijk.
            </p>
          </ChapterIntro.Text>
        </FinalChapterIntro>
      </ChapterIntro>

      {props.children}
    </Section>
  );
}
