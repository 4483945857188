import * as React from 'react';
import styled from 'styled-components/macro';
import useDimensions from '../../hooks/useDimensions';

export const Root = styled('div')`
  flex: 1;
  max-height: 100%;
  max-width: 100%;
`;

export const SVG = styled('svg')`
  width: 100%;
  height: 100%;
  will-change: contents;
`;

function ResponsiveChart(props) {
  const { children } = props;

  const [ref, dimensions] = useDimensions();

  return (
    <Root ref={ref}>
      <SVG {...dimensions}>{children(dimensions)}</SVG>
    </Root>
  );
}

export default ResponsiveChart;
