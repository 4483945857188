import { format } from 'd3-format';
import React, { useMemo, useCallback } from 'react';
import { Tooltip } from './Tooltip';
import { getGroupValue } from './helpers/getGroupValue';
import { getLinkValue } from './helpers/getLinkValue';
import { sourceOrTarget } from './helpers/sourceOrTarget';

function secondsToDuration(t) {
  let m = Math.floor(t / 60);
  let s = Math.abs(t % 60);
  return `${m}m${s}s`;
}

const roundToNearest10 = (x) => Math.ceil((x + 1) / 10) * 10;

export function Tooltips(props) {
  const {
    activeStep,
    selectedGroup,
    groups,
    ribbons,
    metadata,
    innerRadius,
    showTouchLabel,
    hasDuration,
  } = props;

  let formatter = useMemo(() => {
    const sign = activeStep === 'change' ? '+' : '';
    const type = showTouchLabel ? 's' : 'r';
    return format(`${sign},.2~${type}`);
  }, [activeStep, showTouchLabel]);

  const getValue = useCallback(
    function getValue(group) {
      if (!group) return;

      if (group.id === selectedGroup) {
        return getGroupValue(group);
      }

      /**
       * On finding the right chord for this group, from the D3.js docs:
       * a given chord *ij* represents the bidirectional flow from *i* to *j*
       * *and* from *j* to *i*, and does not contain a duplicate chord *ji*.
       */
      let link = ribbons.find((d) => {
        return (
          (d.source.id === selectedGroup && d.target.id === group.id) ||
          (d.target.id === selectedGroup && d.source.id === group.id)
        );
      });

      if (!link) return null;

      return getLinkValue(link, sourceOrTarget(link, selectedGroup));
    },
    [ribbons, selectedGroup]
  );

  const getTooltipText = useCallback(
    (group) => {
      if (hasDuration) {
        if (group.id === selectedGroup) {
          return 'gem. reistijd vanuit';
        }

        const val = roundToNearest10(getValue(group));

        if (!val) return null;
        if (Math.abs(val) <= 10) return null;

        if (activeStep === 'change') {
          return `${val > 0 ? '+' : '-'} ${secondsToDuration(
            Math.abs(val)
          )} naar`;
        }

        return `${secondsToDuration(Math.abs(val))} naar`;
      }

      const val = roundToNearest10(getValue(group));

      if (!val) return null;
      if (Math.abs(val) < 10) return null;

      if (group.id === selectedGroup) {
        return `${formatter(val)} uit`;
      }

      if (activeStep === 'change') {
        // return `gaan er ${formatter(val)} ${val > 0 ? 'meer' : 'minder'} naar`;
        return `${formatter(val)} reizen`;
      }

      return `${formatter(val)} naar`;
    },
    [activeStep, formatter, hasDuration, selectedGroup, getValue]
  );

  if (!selectedGroup) return null;

  if (showTouchLabel) {
    if (hasDuration) return null;

    const origin = metadata[selectedGroup];
    const group = groups.find((d) => d.id === selectedGroup);

    return (
      <g className="tooltips">
        {origin && (
          <Tooltip
            anchor="middle"
            transform={`translate(0.5)`}
            fontSize={'2em'}
            fontWeight={600}
            dy={'0.25em'}
            hasBackground
          >
            {formatter(getValue(group))}
          </Tooltip>
        )}
      </g>
    );
  }

  return (
    <g>
      {groups.map((group) => {
        let text = getTooltipText(group);

        if (!text) return null;

        return (
          <Tooltip
            hasBackground
            fontSize={'12px'}
            key={group.id}
            id={group.id}
            transform={`rotate(${
              (group.textAngle * 180) / Math.PI - 90
            }) translate(${innerRadius - 10}) ${
              group.textAngle > Math.PI ? 'rotate(180)' : ''
            }`}
            anchor={group.textAngle > Math.PI ? 'start' : 'end'}
          >
            {text}
          </Tooltip>
        );
      })}
    </g>
  );
}
