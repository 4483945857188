import * as React from 'react';
import styled from 'styled-components/macro';
import { animated, useSpring } from 'react-spring';
import { scaleLinear } from 'd3-scale';

import { modularScale } from '../../helpers/modular-scale';

const TitleContainer = styled(animated.div)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  z-index: 2;
  transform-style: preserve-3d;
  perspective: 20em;
`;

const Title = styled.h1`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: ${modularScale(6)};
  font-weight: 600;
  line-height: 1.2;
  color: white;
  transition: opacity 40ms linear;
  margin: 0.1em 0 0;
  perspective: -50em;

  @media screen and (min-width: 45em) {
    font-size: ${modularScale(9)};
  }
`;

const Coordinate = styled('span')`
  margin: 1.5em 0;
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  letter-spacing: 0.03em;
  color: white;
`;

const TopCoordinate = styled(Coordinate)`
  justify-self: flex-start;
`;

const BottomCoordinate = styled(Coordinate)`
  justify-self: flex-end;
`;

export default function TitleScreen(props) {
  const { progress } = props;

  // progress -> opacity transform
  const s = scaleLinear().domain([0, 0.25, 1]).range([1, 0, 0]);

  const opacityStyles = useSpring({ opacity: s(progress) });

  const up = useSpring({
    from: { transform: 'translate3d(0, 0vh, 0)' },
    to: { transform: `translate3d(0, -${progress * 50}vh, 0)` },
  });
  const down = useSpring({
    from: { transform: 'translate3d(0, 0vh, 0)' },
    to: { transform: `translate3d(0, ${progress * 50}vh, 0)` },
  });

  return (
    <TitleContainer style={opacityStyles}>
      <TopCoordinate>52.401545, 4.931798</TopCoordinate>
      <Title>
        <animated.span style={up}>Noord</animated.span>
        <animated.span style={down}>Zuid</animated.span>
      </Title>
      <BottomCoordinate>52.338892, 4.871664</BottomCoordinate>
    </TitleContainer>
  );
}
