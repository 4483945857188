import * as React from 'react';
import styled from 'styled-components/macro';

const SpinnerContainer = styled('div')`
  position: relative;

  height: 100vh;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const Spinner = () => (
  <SpinnerContainer>
    <span>loading&hellip;</span>
  </SpinnerContainer>
);
