import React, { useCallback, useMemo, useState, useRef } from 'react';

import RideFrequencyData from '../components/ride-frequency/RideFrequencyData';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { StepCounter } from '../components/StepCounter';
import { SubChapterIntro } from '../components/simple/SubChapterIntro';
import { SubChapterTitle } from '../components/simple/SubChapterTitle';
import { Callout } from '../components/simple/Callout';
import Section from '../components/simple/Section';

import useScrollama from '../hooks/useScrollama';

const steps = [
  'before,map',
  'before,distribution',
  'after,distribution',
  'after,map',
  'change,map',
  'change,distribution',
];

const RideFrequency = (props) => {
  const [activeStep, setActiveStep] = useState('before,map');
  const active = useMemo(() => activeStep.split(','), [activeStep]);

  const container = useRef();

  const handleStepEnter = useCallback(function handleStepEnter(event) {
    let { step } = event.element.dataset;
    setActiveStep(step);
  }, []);

  useScrollama(
    {
      container: container.current,
      step: '#ride-frequency-container [data-step]',
    },
    handleStepEnter,
    'onStepEnter'
  );

  return (
    <Section id="ritfrequentie">
      <SubChapterIntro>
        <SubChapterTitle>Veranderde ritfrequentie</SubChapterTitle>
        <p>
          Dit hoofdstuk laat zien hoe vaak voertuigen stoppen bij een halte:
          ritfrequentie. De haltes zijn gekleurd naar het aantal voertuigen dat
          daar stopt op een doordeweekse dag in september.
        </p>
      </SubChapterIntro>

      <SideScrollingGrid id="ride-frequency-container" ref={container}>
        <SideScrollingGrid.Content>
          <RideFrequencyData activeDataLayer={active} />
        </SideScrollingGrid.Content>

        <SideScrollingGrid.SideStory>
          <Step data-step="before,map">
            <h4>Ritfrequentie op woensdag 12 september 2017</h4>
            <p>
              De haltes zijn gekleurd naar het aantal voertuigen dat daar stopt
              op woensdag 12 september 2017, ongeveer{' '}
              <strong>
                een jaar voor de Noord/Zuidlijn in gebruik word genomen
              </strong>
              .
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>

          <Step data-step="before,distribution">
            <h4>Verdeling van ritfrequentie op woensdag 12 september 2017</h4>
            <p>
              Hier zijn dezelfde haltes gegroepeerd op ritfrequentie. Zo is de
              verdeling van ritten over het netwerk te zien.{' '}
              <Callout bgColor={'#860215'}>Centraal Station</Callout> is de hub
              van het netwerk met bijna 6150 ritten per dag.{' '}
              <Callout bgColor={'rgb(238, 137, 167)'}>Station Zuid</Callout>{' '}
              ontvangt 1412 ritten per dag. Station Noord bestaat nog niet.
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>

          <Step data-step="after,distribution">
            <h4>Verdeling van ritfrequentie op woensdag 4 september 2018</h4>
            <p>
              Dit is de situatie op woensdag 4 september 2018,{' '}
              <strong>
                een maand na de ingebruikname van de Noord/Zuidlijn
              </strong>
              .
            </p>
            <p>
              De ritfrequentie voor veel haltes met een hoge ritfrequentie is
              gedaald. Doordat trams nu aansluiten op het metronetwerk en minder
              over parallelle routes rijden worden{' '}
              <strong>meer reizigers bediend met de metro</strong>. De metro kan
              veel meer reizigers vervoeren in een voertuig dan een tram of een
              bus.
            </p>
            <p>
              <Callout bgColor={'rgb(134, 2, 21)'}>Centraal Station</Callout> is
              nog steeds het grootste station maar ontvangt 800 minder ritten
              per dag.{' '}
              <Callout bgColor={'rgb(237, 142, 173)'}>Station Noord</Callout> is
              nieuw en ontvangt een groot deel van de bussen die voorheen naar
              Centraal Station reden.
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>

          <Step data-step="after,map">
            <h4>Ritfrequentie op woensdag 4 september 2018</h4>
            <p>
              De ritfrequentie op woensdag 4 september 2018 op een kaart, meer
              dan een maand na de ingebruikname van de Noord/Zuidlijn.
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>

          <Step data-step="change,map">
            <h4>Verandering in ritfrequentie tussen 2017 en 2018</h4>
            <p>
              Op deze kaart zie je de veranderingen tussen september 2017 en
              september 2018. De geplande vermindering in parallelliteit is
              duidelijk zichtbaar. Op de binnenring is juist te zien dat het
              aantal ritten is toegenomen.
            </p>
            <p>
              Zo rijden er minder bussen vanuit Amsterdam Noord door de{' '}
              <span
                style={{ background: 'rgb(223, 24, 31)', color: 'white' }}
                data-node=""
              >
                IJ-tunnel
              </span>{' '}
              naar het{' '}
              <span
                style={{ background: 'rgb(223, 24, 31)', color: 'white' }}
                data-node=""
              >
                Centraal Station
              </span>
              . Daarnaast is het{' '}
              <span
                style={{ background: 'rgb(223, 24, 31)', color: 'white' }}
                data-node=""
              >
                Spui
              </span>{' '}
              een van de haltes waar de ritfrequentie het meest gedaald is,
              omdat vlakbij op het{' '}
              <span
                style={{ background: 'rgb(11, 52, 157)', color: 'white' }}
                data-node=""
              >
                Rokin
              </span>{' '}
              nu de Noord/Zuidlijn rijdt. Datzelfde is gebeurd bij het{' '}
              <span
                style={{ background: 'rgb(223, 24, 31)', color: 'white' }}
                data-node=""
              >
                Weteringcircuit
              </span>{' '}
              en de{' '}
              <span
                style={{ background: 'rgb(11, 52, 157)', color: 'white' }}
                data-node=""
              >
                Vijzelgracht
              </span>
              .
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>

          <Step data-step="change,distribution">
            <h4>
              Distributie van verandering in ritfrequentie tussen 2017 en 2018
            </h4>
            <p>
              In deze visualisatie zie je de verdeling van de veranderingen
              tussen september 2017 en september 2018.
            </p>
            <p>
              Ook hier is de vermindering van parallelliteit te zien. De haltes
              aan de linkerkant van de visualisatie hebben meestal een tegenpool
              aan de rechterkant. Daarnaast zijn er meer haltes waar minder
              voertuigen per dag stoppen dan haltes waar meer voertuigen per dag
              stoppen.
            </p>
            <StepCounter steps={steps} activeStep={activeStep} />
          </Step>
        </SideScrollingGrid.SideStory>
      </SideScrollingGrid>
    </Section>
  );
};

export default RideFrequency;
