import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components/macro';

import { SubChapterTitle } from '../components/simple/SubChapterTitle';
import OriginDestinationData from '../components/origin-destination/OriginDestinationData';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { SubChapterIntro } from '../components/simple/SubChapterIntro';
import { StepCounter } from '../components/StepCounter';
import useScrollama from '../hooks/useScrollama';
import Section from '../components/simple/Section';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import {
  totalTimeSavedDay,
  travellerTimeSavedDay,
  avgJourneysWorkday,
} from '../constants';

const Background = styled('div')`
  background: var(--light-gray);
  min-width: 100%;
`;

const steps = [
  'before-10-false',
  'before-10,2-false',
  'before-10,2-10,2',
  'before-*-2',
  'before-*-*',
  'after-*-*',
  'change-*-*',
];

function OriginDestinationTime(props) {
  const [ref, isVisible] = useIntersectionObserver(0.01);

  const [step, setStep] = useState('before');
  const [groups, setGroups] = useState(['10']);
  const [chords, setChords] = useState(['false']);

  const handleStepEnter = useCallback(function handleStepEnter(event) {
    let step, groups, chords;

    ({ step } = event.element.dataset);
    [step, groups, chords] = step.split('-');

    groups = groups.split(',');
    chords = chords.split(',');

    setStep(step);
    setGroups(groups);
    setChords(chords);
  }, []);

  const container = useRef();

  useScrollama(
    {
      container: container.current,
      step: '#od-time-container [data-step]',
    },
    handleStepEnter,
    'onStepEnter'
  );

  return (
    <Section id="7500-uur" ref={ref}>
      <SubChapterIntro>
        <SubChapterTitle>
          {totalTimeSavedDay.toLocaleString('nl-NL')} uur
        </SubChapterTitle>
        <p>
          De gemiddelde tijdswinst die de Noord/Zuidlijn een reiziger in het
          Amsterdamse OV heeft opgeleverd is{' '}
          <em>{travellerTimeSavedDay} seconden</em>. Bij gemiddeld{' '}
          {avgJourneysWorkday.toLocaleString('nl-NL')} reizigers per werkdag
          betekent dit dat er door reizigers ruim{' '}
          {totalTimeSavedDay.toLocaleString('nl-NL')} uur per dag minder tijd in
          het Amsterdamse OV netwerk wordt doorgebracht.
        </p>
        <p>
          21% van de reizigers heeft een reistijdwinst van meer dan 1 minuut en
          13% van de reizigers heeft een reistijdverlies van meer dan 1 minuut.
          Ongeveer 3% van de reizigers heeft een grote reistijdwinst van 10
          minuten of meer. In dit hoofdstuk kun je zien hoe deze tijdswinst is
          opgebouwd.
        </p>
      </SubChapterIntro>
      <Background>
        <SideScrollingGrid ref={container} id="od-time-container">
          <SideScrollingGrid.Content>
            <OriginDestinationData
              activeStep={step}
              activeGroups={groups}
              activeChords={chords}
              dataAccessor="travelTime"
              legendLabel="verandering reistijd"
              isVisible={isVisible}
              hasReversedColors
              hasDuration
            />
          </SideScrollingGrid.Content>
          <SideScrollingGrid.SideStory>
            <Step data-step="before-10-false">
              <h4>Totaal gemiddelde reistijd vanaf Amsterdam Centraal</h4>
              <p>
                Deze streep staat voor Amsterdam Centraal. De breedte van de
                streep is het totaal van gemiddelde reistijden van Amsterdam
                Centraal naar de andere zones in het Amsterdamse OV.
              </p>
              <StepCounter steps={steps} activeStep={steps[0]} />
            </Step>

            <Step data-step="before-10,2-false">
              <h4>Totaal gemiddelde reistijd vanaf Weesperbuurt</h4>
              <p>
                Deze tweede streep staat voor zone Weesperbuurt. Onder andere
                het Weesperplein valt hieronder. Elke zone in het Amsterdamse
                openbaar vervoer heeft zijn eigen streep. Ook hier staat de
                breedte voor het totaal gemiddelde reistijd vanuit zone
                Weesperbuurt.
              </p>
              <StepCounter steps={steps} activeStep={steps[1]} />
            </Step>

            <Step data-step="before-10,2-10,2">
              <h4>
                Gemiddelde reistijd tussen Amsterdam Centraal en de Weesperbuurt
                op een werkdag in najaar 2017
              </h4>
              <p>
                Deze lijn staat voor de gemiddelde reistijd op een werkdag in
                najaar 2017 tussen zone Weesperbuurt en Amsterdam Centraal.
              </p>
              <p>
                De reisrelatie tussen Amsterdam Centraal en het Weesperplein is
                een van de drukste van de stad, met dagelijks rond de 9,000
                reizigers.
              </p>
              <StepCounter steps={steps} activeStep={steps[2]} />
            </Step>

            <Step data-step="before-*-2">
              <h4>
                Reistijd tussen de Weesperbuurt en alle andere zones in najaar
                2017
              </h4>
              <p>
                Hier zijn alle gemiddelde reistijden vanuit zone Weesperbuurt
                naar de andere zones in de stad te zien.
              </p>
              <StepCounter steps={steps} activeStep={steps[3]} />
            </Step>

            <Step data-step="before-*-*">
              <h4>Gemiddelde reistijden tussen alle zones in najaar 2017</h4>
              <p>
                De lijnen laten nu de gemiddelde reistijd zien tussen alle zones
                in het Amsterdamse OV. Door met de muis te hoveren over een zone
                is de precieze gemiddelde reistijd zien tussen die zone en de
                andere zones.
              </p>
              <StepCounter steps={steps} activeStep={steps[4]} />
            </Step>

            <Step data-step="after-*-*">
              <h4>Gemiddelde reistijd tussen alle zones in najaar 2018</h4>
              <p>
                Tot zover heb je gekeken naar data uit 2017, vóór de
                ingebruikname van de Noord/Zuidlijn. Deze nieuwe data laat zien
                hoelang mensen gemiddeld reizen na de ingebruikname van de
                Noord/Zuidlijn.
              </p>
              <StepCounter steps={steps} activeStep={steps[5]} />
            </Step>

            <Step data-step="change-*-*">
              <h4>
                Verandering in gemiddelde reistijd tussen najaar 2017 en najaar
                2018
              </h4>

              <p>
                Hier is duidelijk de impact van de Noord/Zuidlijn op de
                gemiddelde reistijd in het OV in Amsterdam te zien. Vanuit elke
                zone waar een Noord/Zuidlijn metrostation ligt (Noord-Midden
                (Station Noord, Noorderpark), Centraal, Spui (Rokin),
                Vijzelgracht, de Pijp) is een lagere reistijd te zien naar
                praktisch alle andere zones.
              </p>

              <p>
                Verder valt op dat het Amsterdamse OV over het algemeen genomen
                sneller lijkt te zijn geworden. Voor bijna elke
                herkomst/bestemmingsrelatie geldt dat de gemiddelde reistijd op
                een gemiddelde werkdag gedaald is. Slechts in enkele zones is
                een geringe groei van de gemiddelde reistijd te zien.
              </p>
              <StepCounter steps={steps} activeStep={steps[6]} />
            </Step>
          </SideScrollingGrid.SideStory>
        </SideScrollingGrid>
      </Background>
    </Section>
  );
}

export default OriginDestinationTime;
