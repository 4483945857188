import styled from 'styled-components/macro';

const Section = styled('section')`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  width: 100%;
`;

export default Section;
