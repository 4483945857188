import { useEffect, useRef, useState } from 'react';

export function useIntersectionObserver(threshold = 0.01) {
  const [intersecting, setIntersecting] = useState(false);

  const node = useRef();

  useEffect(() => {
    const cb = ([entry]) => setIntersecting(entry.isIntersecting);
    const observer = new IntersectionObserver(cb, { threshold });

    if (node.current) {
      observer.observe(node.current);
    }

    return () => observer.disconnect();
  }, [threshold]);

  return [node, intersecting];
}
