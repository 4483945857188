import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ChapterIntro } from '../components/simple/ChapterIntroduction';
import Section from '../components/simple/Section';
import { useScrollToTop } from '../hooks/useScrollToTop';
import reizigers from '../images/reizigers.jpg';

const CommuterHome = (props) => {
  const location = useLocation();
  useScrollToTop(location.pathname);

  return (
    <Section>
      <ChapterIntro>
        <ChapterIntro.Grid>
          <ChapterIntro.Heading>
            Verandering voor de reiziger
          </ChapterIntro.Heading>

          <ChapterIntro.Image
            src={reizigers}
            alt="Reizigers in tram lijn 26 richting IJburg"
          />

          <ChapterIntro.Text>
            Niet alleen voor de vervoerder maar ook voor de reiziger is er veel
            veranderd in het Amsterdamse openbaar vervoer. Reizigers reizen
            andere routes met een andere reistijd, soms zelfs vanaf een andere
            opstaphalte. In dit hoofdstuk vergelijken we het reisgedrag in het
            najaar van 2017 (11 september t/m 15 oktober) met het reisgedrag na
            ingebruikname van de Noord/Zuidlijn in het najaar van 2018 (10
            september t/m 21 oktober).
          </ChapterIntro.Text>
        </ChapterIntro.Grid>
      </ChapterIntro>

      {props.children}
    </Section>
  );
};

export default CommuterHome;
