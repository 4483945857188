import React, { useState } from 'react';

import { Spinner } from '../Spinner';

import ToggleList from '../ToggleList';
import ChangedLinesMap from './ChangedLinesMap';
import { FilterContainer } from '../simple/DataContainer';
import { Container } from '../simple/Container';
import { basePath } from '../../constants';
import useSWRImmutable from 'swr/immutable';

function ChangedLinesData(props) {
  const [activeModality, setActiveModality] = useState('all');

  const { data, error } = useSWRImmutable(
    `${basePath}/changed-lines/gvb-routes.geojson`
  );

  const loading = !data && !error;

  return (
    <>
      <Container>
        <FilterContainer>
          <ToggleList
            legend="Modaliteit"
            name="modality"
            options={[
              {
                id: 'all',
                label: 'Alle',
              },
              {
                id: 'bus',
                label: 'Bus',
              },
              {
                id: 'tram',
                label: 'Tram',
              },
              {
                id: 'metro',
                label: 'Metro',
              },
            ]}
            selectedOption={activeModality}
            onChange={setActiveModality}
          />
        </FilterContainer>
      </Container>
      {loading && <Spinner />}
      {!loading && (
        <ChangedLinesMap routes={data} activeModality={activeModality} />
      )}
    </>
  );
}

export default ChangedLinesData;
