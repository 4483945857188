/** Data files base path */
export const basePath = `${process.env.PUBLIC_URL}/data`;

/**
 * This locale (nl-NL) is used to configure d3-format.
 */
export const locale = {
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['€\u00a0', ''],
};

export const avgJourneysWorkday = 650000;
export const totalTimeSavedDay = 7500; // hours
export const travellerTimeSavedDay = 43; // seconds

export const paths = [
  {
    path: '/',
    label: 'Introductie',
  },
  {
    path: '/verandering-in-het-openbaar-vervoer',
    label: 'Verandering in het openbaar vervoer',
  },
  {
    path: '#andere-tijden-andere-lijnen',
    label: 'Andere tijden, andere lijnen',
    belongsTo: '/verandering-in-het-openbaar-vervoer',
  },
  {
    path: '#ritfrequentie',
    label: 'Ritfrequentie',
    belongsTo: '/verandering-in-het-openbaar-vervoer',
  },
  {
    path: '/verandering-voor-de-reiziger',
    label: 'Verandering voor de reiziger',
  },
  {
    path: '#650000-reizen',
    label: `${avgJourneysWorkday.toLocaleString('nl-NL')} reizen`,
    belongsTo: '/verandering-voor-de-reiziger',
  },
  {
    path: '#7500-uur',
    label: `${totalTimeSavedDay.toLocaleString('nl-NL')} uur`,
    belongsTo: '/verandering-voor-de-reiziger',
  },
];

export const status = {
  removed: '#FF980A',
  new: '#EF0001',
  changed: '#315FA3',
  unchanged: '#666',
  'changed-new-state': '#315FA3',
};

const diverging = [
  '#860215',
  '#EF0001',
  '#E77789',
  '#E2CBEC',
  '#A8B4EC',
  '#0e69da',
  '#0b349d',
];

const sequential = [
  '#F1C4E2',
  '#E894B2',
  '#F57494',
  '#FC4E70',
  '#DD2847',
  '#AC1628',
  '#860215',
];

export const colors = {
  diverging,
  sequential,
  red: '#ec0001',
  lightGray: '#f0f0f3',
};
