import styled from 'styled-components/macro';
import * as React from 'react';
import { modularScale } from '../../helpers/modular-scale';
import { quantize } from 'd3-interpolate';
import { colors } from '../../constants';

const LegendScale = styled('rect')`
  stroke: none;
`;

const Label = styled('text')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

const Bound = styled(Label)`
  font-weight: 400;
`;

export function Legend({ scale, id, type, label, position }) {
  if (type === 'color') {
    let samples = quantize(scale.interpolator(), colors.diverging.length);

    return (
      <g transform={`translate(${position})`}>
        <defs>
          <linearGradient x1={0} x2={1} y1={0} y2={0} id={`gradient-${id}`}>
            {samples.map((color, i) => (
              <stop
                key={`sample-${color}`}
                offset={`${i * (100 / (samples.length - 1))}%`}
                stopColor={color}
              />
            ))}
          </linearGradient>
        </defs>
        <LegendScale
          fill={`url(#gradient-${id})`}
          width="242"
          height="8"
          x="4"
          y="30"
        />
        <Label textAnchor="start" x="4" y="8" dy="12">
          {label}
        </Label>
        <Bound x="4" y="42" dy="12">
          minder
        </Bound>
        <Bound x="246" y="42" dy="12" textAnchor="end">
          meer
        </Bound>
      </g>
    );
  }
}
