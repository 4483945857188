import React, { useMemo } from 'react';
import { tile } from 'd3-tile';

const baseMapType = 'cjn1nq36k02872so9xlvsf858';
const redMapType = 'cjnd86lu80fbj2rs9rpklzcmt';

function getMapUrl(isRedMap) {
  const mapType = isRedMap ? redMapType : baseMapType;
  return `https://api.mapbox.com/styles/v1/nickcf/${mapType}/tiles/256`;
}

function stringify(scale, translate) {
  let k = scale / 256;
  let r = scale % 1 ? Number : Math.round;

  return `translate(${r(translate[0] * scale)},${r(
    translate[1] * scale
  )}) scale(${k})`;
}

const getTileUrl = (url, z, x, y) =>
  `${url}/${z}/${x}/${y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

const tau = 2 * Math.PI;

function TileLayer(props) {
  const { redMap = false, tileSize = 256, projection, width, height } = props;

  const baseUrl = useMemo(() => getMapUrl(redMap), [redMap]);

  const layout = useMemo(() => {
    return tile()
      .size([width, height])
      .scale(projection.scale() * tau)
      .translate(projection([0, 0]))();
  }, [projection, width, height]);

  if (layout.length === 0) return null;

  return (
    <g transform={stringify(layout.scale, layout.translate)}>
      {layout.map(([x, y, z]) => (
        <image
          shapeRendering="crispEdges"
          x={`${x * tileSize}`}
          y={`${y * tileSize}`}
          key={getTileUrl(baseUrl, z, x, y)}
          width={tileSize + 1} // Make sure the tiles connect through a little hack
          height={tileSize + 1} // Make sure the tiles connect through a little hack
          xlinkHref={getTileUrl(baseUrl, z, x, y)}
        />
      ))}
    </g>
  );
}

export default React.memo(TileLayer);
