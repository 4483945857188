import React, { useState, useCallback } from 'react';
import styled from 'styled-components/macro';

import { status } from '../constants';
import useScrollama from '../hooks/useScrollama';
import { modularScale } from '../helpers/modular-scale';

import ChangedLinesData from '../components/changed-lines/ChangedLinesData';
import { SubChapterTitle } from '../components/simple/SubChapterTitle';
import KPIList from '../components/KPIList';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { SubChapterIntro } from '../components/simple/SubChapterIntro';
import { StepCounter } from '../components/StepCounter';
import { Callout } from '../components/simple/Callout';
import Section from '../components/simple/Section';

const LargeKPI = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin: -0.25rem;

  > * {
    margin: 0.25rem;
  }
`;

const KPI = styled('span')`
  font-size: ${modularScale(2)};
  font-family: var(--harmonia);
  font-weight: 600;
  line-height: 1;
  color: ${({ color }) => color};
`;

const Label = styled('span')`
  font-size: ${modularScale(0)};
  font-weight: bold;
  font-family: var(--harmonia);
  letter-spacing: 0.02em;
`;

const defaultContextValue = {
  activeStep: 'unchanged,changed-old-state,removed',
  steps: [
    'unchanged,changed-old-state,removed',
    'changed-old-state,changed-new-state',
    'new',
    'removed',
    'unchanged,changed-new-state,new',
  ],
  colors: {
    'unchanged,changed-old-state,removed': status.new,
    removed: status.removed,
    'changed-old-state,changed-new-state': status.changed,
    new: status.new,
    'unchanged,changed-new-state,new': status.new,
  },
};

export const ChangedLinesContext = React.createContext();

function ChangedLinesStepCounter(props) {
  return (
    <ChangedLinesContext.Consumer>
      {(ctx) => (
        <StepCounter steps={ctx.steps} activeStep={ctx.activeStep} {...props} />
      )}
    </ChangedLinesContext.Consumer>
  );
}

function ChangedLinesKPI(props) {
  const { color, children, ...rest } = props;
  return (
    <ChangedLinesContext.Consumer>
      {({ colors }) => (
        <KPI color={colors[color]} {...rest}>
          {children}
        </KPI>
      )}
    </ChangedLinesContext.Consumer>
  );
}

function ChangedLinesCallout(props) {
  const { color, children, ...rest } = props;
  return (
    <ChangedLinesContext.Consumer>
      {({ colors }) => (
        <Callout bgColor={colors[color]} {...rest}>
          {children}
        </Callout>
      )}
    </ChangedLinesContext.Consumer>
  );
}

function ChangedLines() {
  const [activeStep, setActiveStep] = useState(
    'unchanged,changed-old-state,removed'
  );

  const handleStepEnter = useCallback((event) => {
    let { step } = event.element.dataset;
    setActiveStep(step);
  }, []);

  useScrollama(
    {
      container: '#changed-lines-container',
      step: '#changed-lines-container [data-step]',
    },
    handleStepEnter,
    'onStepEnter'
  );

  return (
    <Section id="andere-tijden-andere-lijnen">
      <SubChapterIntro>
        <SubChapterTitle>Andere tijden, andere lijnen</SubChapterTitle>
        <p>
          De Noord/Zuidlijn bestaat om het groeiend aantal reizigers van
          Amsterdam te vervoeren en om Amsterdam Noord beter met de stad te
          verbinden. Door de Noord/Zuidlijn—en de bestaande metro-lijnen—in te
          zetten als de nieuwe ruggengraat van het openbaar vervoersnetwerk
          verandert de structuur van het netwerk van een spinnenweb naar een
          visgraat.
        </p>
      </SubChapterIntro>

      <ChangedLinesContext.Provider
        value={{ ...defaultContextValue, activeStep }}
      >
        <SideScrollingGrid id="changed-lines-container">
          <SideScrollingGrid.Content>
            <ChangedLinesData />
          </SideScrollingGrid.Content>

          <SideScrollingGrid.SideStory>
            <Step data-step="unchanged,changed-old-state,removed">
              <LargeKPI>
                <KPI color={'var(--red)'}>56</KPI>
                <Label>lijnen</Label>
              </LargeKPI>
              <p>
                Voor de ingebruikname van de Noord/Zuidlijn op 22 juli 2018 zag
                het OV van Amsterdam er zo uit.
              </p>
              <ChangedLinesStepCounter />
            </Step>

            <Step data-step="changed-old-state,changed-new-state">
              <LargeKPI>
                <ChangedLinesKPI color={'changed-old-state,changed-new-state'}>
                  12
                </ChangedLinesKPI>
                <Label>veranderde lijnen</Label>
              </LargeKPI>
              <p>
                Van 12 lijnen is de route of het nummer veranderd sinds de komst
                van de Noord/Zuidlijn. Door bussen en trams aan te laten sluiten
                op de metro kunnen reizigers{' '}
                <ChangedLinesCallout
                  color={'changed-old-state,changed-new-state'}
                >
                  sneller reizen
                </ChangedLinesCallout>{' '}
                en vervoerders{' '}
                <ChangedLinesCallout
                  color={'changed-old-state,changed-new-state'}
                >
                  efficiënter vervoeren
                </ChangedLinesCallout>
                . Zo rijden enkele buslijnen in Noord{' '}
                <strong
                  style={{
                    border: `2px dashed ${status.changed}`,
                    fontWeight: 400,
                  }}
                >
                  niet meer
                </strong>{' '}
                naar het Centraal Station maar naar station Noord of Noorderpark
                waar reizigers kunnen overstappen op de Noord/Zuidlijn.
              </p>
              <ChangedLinesStepCounter />
            </Step>

            <Step data-step="new">
              <LargeKPI>
                <ChangedLinesKPI color={'new'}>3</ChangedLinesKPI>
                <Label>nieuwe lijnen</Label>
              </LargeKPI>
              <p>
                De Noord/Zuidlijn rijdt sinds 22 juli 2018. De metrolijnen zijn
                sindsdien de ruggengraat van het Amsterdamse OV netwerk.
              </p>
              <p>
                Naast metrolijn{' '}
                <ChangedLinesCallout color={'new'}>52</ChangedLinesCallout> zijn
                ook tramlijn{' '}
                <ChangedLinesCallout color={'new'}>19</ChangedLinesCallout> en
                tramlijn{' '}
                <ChangedLinesCallout color={'new'}>11</ChangedLinesCallout>{' '}
                nieuw. Tram 19 is de langste tramlijn in Amsterdam—van
                Sloterdijk naar Diemen—en vervangt deels tram 12.
              </p>
              <ChangedLinesStepCounter />
            </Step>

            <Step data-step="removed">
              <LargeKPI>
                <ChangedLinesKPI color={'removed'}>6</ChangedLinesKPI>
                <Label>opgeheven lijnen</Label>
              </LargeKPI>
              <p>
                Zes lijnen zijn opgeheven om parallelliteit te verminderen.
                Parallelliteit bestaat als verschillende lijnen over hetzelfde
                traject rijden.
              </p>
              <p>
                <ChangedLinesCallout color={'removed'}>
                  Door parallelliteit te verminderen werkt het OV netwerk
                  efficiënter.
                </ChangedLinesCallout>{' '}
                Zo is het mogelijk om met hetzelfde aantal voertuigen en
                chauffeurs nu meer haltes vaker te bedienen.
              </p>
              <ChangedLinesStepCounter />
            </Step>

            <Step data-step="unchanged,changed-new-state,new">
              <LargeKPI>
                <KPI color={'black'}>52</KPI>
                <Label>lijnen</Label>
              </LargeKPI>
              <p>
                Na de ingebruikname van de Noord/Zuidlijn ziet het OV van
                Amsterdam er zo uit.
              </p>
              <ChangedLinesContext.Consumer>
                {({ colors }) => (
                  <KPIList
                    indicators={[
                      {
                        label: 'Nieuwe routes',
                        value: 3,
                        color: colors['new'],
                      },
                      {
                        label: 'Gewijzigde routes',
                        value: 12,
                        color: colors['changed-old-state,changed-new-state'],
                      },
                      {
                        label: 'Ongewijzigde routes',
                        value: 37,
                        color: '#666',
                      },
                    ]}
                  />
                )}
              </ChangedLinesContext.Consumer>
              <ChangedLinesStepCounter />
            </Step>
          </SideScrollingGrid.SideStory>
        </SideScrollingGrid>
      </ChangedLinesContext.Provider>
    </Section>
  );
}

export default ChangedLines;
