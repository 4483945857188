/**
 * sourceOrTarget returns the right link from a d3.js chord layout.
 *
 * Chord layouts in D3.js are based on a square matrix of size n*n,
 * where the matrix represents the directed flow amongst a network
 * (a complete digraph) of n nodes. The given matrix must be an
 * array of length n, where each element matrix[i] is an
 * array of n numbers, where each matrix[i][j] represents the flow
 * from the ith node in the network to the jth node. Each number
 * matrix[i][j] must be nonnegative, though it can be zero if there
 * is no flow from node i to node j.
 *
 * The return value of chord(matrix) is an array of chords, where each
 * chord represents the combined bidirectional flow between two nodes
 * i and j (where i may be equal to j).
 *
 * The returned array includes only chord objects for which the value
 * matrix[i][j] or matrix[j][i] is non-zero. Furthermore, the returned
 * array only contains unique chords: a given chord ij represents the
 * bidirectional flow from i to j and from j to i, and does not
 * contain a duplicate chord ji; i and j are chosen such that the chord’s
 * source always represents the larger of matrix[i][j] and matrix[j][i].
 *
 * So each chord is an object containing a 'source' and 'target' object,
 * that describe the bidirectional flow between source and target links.
 * source is always the object with the highest value. This helper
 * function allows you to find the right origin group (matrix[i][j] OR
 * matrix[j][i]) to help you find the value of that chord based on the id
 * of the group node.
 *
 * Most of the documentation of this function comes from: https://github.com/d3/d3-chord
 *
 * @param {object} chord
 * @param {string} group
 */
export function sourceOrTarget(chord, group) {
  if (!chord || !group) return 'source';
  if (chord.source.id === group) return 'source';
  return 'target';
}
