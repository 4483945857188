import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import modularScale from '../../helpers/modular-scale';
import { hideVisually } from 'polished';

export const Nav = styled.nav`
  display: grid;
  place-items: center;
  min-height: 80vh;
  background: white;
`;

export const List = styled.ul`
  position: relative;
  padding: initial;
  margin: 2em 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    z-index: 0;
    transform: translateX(-50%);
    background: black;
  }
`;

export const ListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 1;

  &:not(:last-of-type) {
    margin: 0 0 4em 0;
  }
`;

export const Link = styled(NavLink)`
  position: relative;
  font-family: var(--harmonia);
  font-weight: medium;
  font-size: ${modularScale(2)};
  text-align: center;
  text-decoration: none;
  color: black;
  line-height: 1.2;
  max-width: 20ch;
  padding: 1em 0;

  &:hover,
  &:active,
  &[aria-current] {
    color: var(--red);
  }

  &:hover,
  &:active {
    text-decoration: revert;
  }
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;
