import * as React from 'react';
import styled from 'styled-components/macro';
import { modularScale } from '../helpers/modular-scale';

const Fieldset = styled('fieldset')`
  border: none;
  padding: 0;
`;

const ToggleGroup = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

const ToggleTitle = styled('legend')`
  margin: 0 1em 0 0;
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

const LabelGroup = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: white;
`;

const Label = styled('label')`
  flex: 1;
  display: block;
  font-family: var(--harmonia);
  font-size: ${modularScale(-1)};
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  padding: 0.75em 1.5em;
  border: 1px solid var(--light-gray);

  + label {
    transform: translateX(-1px);
  }

  &:hover {
    cursor: pointer;
    border: ${({ active }) => !active && '1px solid black'};
    z-index: 666;
  }

  ${({ active }) => {
    if (active) {
      return `
      border: 1px solid var(--red);
        background: var(--red);
        color: white;
      `;
    }
  }};
`;

const RadioInput = styled('input')`
  display: none;
`;

export default function ToggleList({
  legend,
  options,
  name,
  selectedOption,
  onChange,
}) {
  return (
    <form>
      <Fieldset>
        <ToggleGroup>
          <ToggleTitle>{legend}</ToggleTitle>
          <LabelGroup>
            {options.map(({ id, label }) => (
              <Label
                key={`${name}-${id}`}
                htmlFor={`${name}-${id}`}
                active={id === selectedOption}
              >
                <RadioInput
                  type="radio"
                  name={`toggle-${name}`}
                  id={`${name}-${id}`}
                  value={id}
                  checked={id === selectedOption}
                  onChange={(e) => onChange(e.target.value)}
                />
                <span>{label}</span>
              </Label>
            ))}
          </LabelGroup>
        </ToggleGroup>
      </Fieldset>
    </form>
  );
}
