import styled from 'styled-components/macro';
import * as React from 'react';
import { modularScale } from '../../helpers/modular-scale';
import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';

const LegendScale = styled('rect')`
  stroke: none;
`;

const Label = styled('text')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

const Bound = styled(Label)`
  font-weight: 400;
`;

let formatter = format(',.2~r');

export function Legend({ scale, range, type, label, position }) {
  if (!scale) return null;
  if (scale.domain().every(isNaN)) return null;

  const x = scaleLinear().domain(scale.domain()).range([4, 242]);

  // let interpolator = interpolate(...extent(scale.thresholds()));
  // let samples = quantize(interpolator, range.length);

  return (
    <g className="legend" transform={`translate(${position})`}>
      <defs>
        <linearGradient x1={0} x2={1} y1={0} y2={0} id="scale-gradient">
          {scale.thresholds().map((d, i) => (
            <stop
              key={`sample-${d}`}
              offset={`${Math.round(
                i * (100 / (scale.thresholds().length - 1))
              )}%`}
              stopColor={scale(d)}
            />
          ))}
        </linearGradient>
      </defs>

      <LegendScale
        fill="url(#scale-gradient)"
        width="242"
        height="8"
        x="4"
        y="30"
      />

      <Label textAnchor="start" x="4" y="8" dy="12">
        {label}
      </Label>

      <Bound x={x(scale.domain()[0])} y="42" dy="12">
        {formatter(scale.domain()[0])}
      </Bound>

      {scale.domain()[0] < 0 && (
        <Bound x={x(0)} y="42" dy="12" textAnchor={'middle'}>
          0
        </Bound>
      )}

      <Bound
        x={x(scale.domain()[scale.domain().length - 1])}
        y="42"
        dy="12"
        textAnchor="end"
      >
        {type === 'change' && '+'}
        {formatter(scale.domain()[scale.domain().length - 1])}
      </Bound>
    </g>
  );
}
