import { csvParse } from 'd3-dsv';

export const parseCSV = (response) => {
  if (
    response.headers.get('content-length') === '0' ||
    response.status === 204
  ) {
    return;
  }

  const csv = response.text();

  if (response.status >= 200 && response.status < 300) {
    return csv.then(
      (text) =>
        new Promise((resolve, reject) => {
          try {
            let parsedData = csvParse(text);
            resolve(parsedData);
          } catch (err) {
            reject(err);
          }
        })
    );
  } else {
    return csv.then((cause) => Promise.reject(new Error(cause)));
  }
};
