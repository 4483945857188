import styled from 'styled-components/macro';

const BaseContainer = styled('div')`
  display: flex;
`;

export const DataContainer = styled(BaseContainer)`
  max-width: 100%;
  width: 100%;
`;

export const FilterContainer = styled(DataContainer)`
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: -0.25em;
  z-index: 666;

  > * {
    margin: 0.25em;
  }
`;
