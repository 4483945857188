import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import { useLocation } from 'react-router-dom';

import { modularScale } from '../helpers/modular-scale';
import useScrollama from '../hooks/useScrollama';

import Section from '../components/simple/Section';
import { TextContent } from '../components/simple/TextContent';
import TitleScreen from '../components/introduction/TitleScreen';
import IntroductionMap from '../components/introduction/IntroductionMap';
import { useScrollToTop } from '../hooks/useScrollToTop';

const Header = styled('header')`
  position: relative;
  background: var(--red);
`;

const StickyContainer = styled('div')`
  will-change: contents;
  position: sticky;
  overflow: hidden;
  z-index: 2;
  top: 0;
`;

const StyledSection = styled(Section)`
  align-items: center;
  position: relative;
  z-index: 3;
  padding: 0 0.75rem;
`;

const SubtitleContainer = styled('div')`
  max-width: 35em;
  height: 100%;
  padding: 0;
`;

const StickyContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 2rem;
`;

const StyledTextContent = styled(TextContent)`
  margin: 2em 0 100%;
  padding: 1em 1.5em;
  color: white;
  background: ${transparentize(0.3, '#ec0001')};
  font-size: 0.9em;

  @media screen and (min-width: 45em) {
    font-size: 1.25em;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Subtitle = styled('h2')`
  margin: 0.25em 0;
  font-size: ${modularScale(1)};
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: white;

  @media screen and (min-width: 45em) {
    font-size: 3.5em;
    text-align: center;
  }
`;

const Steps = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

const StepOne = styled('div')`
  margin-top: 50%;
  height: 30%;
`;

export default function Introduction(props) {
  const location = useLocation();
  useScrollToTop(location.pathname);

  const [progress, setProgress] = useState(0);

  const handleStepProgress = useCallback((event) => {
    const { progress } = event;
    setProgress(progress);
  }, []);

  const container = useRef();
  const graphic = useRef();

  useScrollama(
    {
      container: container.current,
      graphic: graphic.current,
      step: '#scrollama-steps .step',
      progress: true,
      threshold: 25,
    },
    handleStepProgress,
    'onStepProgress'
  );

  return (
    <Header ref={container}>
      <StickyContainer ref={graphic}>
        <TitleScreen progress={progress} />
        <IntroductionMap progress={progress} />
      </StickyContainer>

      <StyledSection>
        <SubtitleContainer>
          <StickyContent>
            <Subtitle>De grootste verandering sinds de paardentram</Subtitle>
          </StickyContent>
        </SubtitleContainer>

        <StyledTextContent>
          <p>
            Vanaf 22 juli 2018 rijdt de Noord/Zuidlijn. Daarnaast zijn er
            veranderingen doorgevoerd in de lijnen en dienstregeling van stads-
            en streekvervoerders in en om Amsterdam.
          </p>
          <p>
            <strong>
              Hoe groot is de impact van deze veranderingen op het openbaar
              vervoer in en om de stad en op reizigers?
            </strong>
          </p>
        </StyledTextContent>
      </StyledSection>

      <Steps id="scrollama-steps">
        <StepOne className="step" />
      </Steps>
    </Header>
  );
}
