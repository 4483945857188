import * as React from 'react';
import { useMemo } from 'react';
import { Delaunay } from 'd3-delaunay';

export default Cells;

function Cells(props) {
  const { nodes, handleNodeInteraction, width, height, type } = props;

  const voronoi = useMemo(
    () =>
      Delaunay.from(
        nodes,
        (d) => d[type]?.x ?? 0,
        (d) => d[type]?.y ?? 0
      ).voronoi([0, 0, width, height]),
    [height, nodes, type, width]
  );

  return (
    <g
      className="cells"
      onMouseMove={handleNodeInteraction}
      onMouseOut={handleNodeInteraction}
      onTouchEnd={handleNodeInteraction}
    >
      {nodes.map((node, idx) => (
        <path
          key={`cell-${node.id}`}
          data-node={node.id}
          d={voronoi.renderCell(idx)}
          stroke="transparent"
          fill="transparent"
        />
      ))}
    </g>
  );
}
