import { useEffect, useRef } from 'react';
import scrollama from 'scrollama';

export default useScrollama;

function useScrollama(
  options = {},
  callback = () => {},
  handler = 'onStepProgress'
) {
  if (!options.hasOwnProperty('step')) {
    throw new Error(
      'You need to pass a step selector to the scrollama constructor. See https://github.com/russellgoldenberg/scrollama'
    );
  }

  const args = useRef(options);

  useEffect(() => {
    // For information about how scrollama works, refer to:
    // https://github.com/russellgoldenberg/scrollama

    const scroller = scrollama();

    scroller.setup(args.current);
    scroller[handler](callback);

    function handleWindowResize() {
      scroller.resize();
    }

    window.addEventListener('resize', handleWindowResize);

    return function onUnmount() {
      window.removeEventListener('resize', handleWindowResize);
      scroller.disable();
      scroller.destroy();
    };
  }, [callback, handler]);
}
