import * as React from 'react';
import styled from 'styled-components/macro';
import { Switch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

const AnimatedBlock = styled(animated.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  will-change: transform;
  pointer-events: none;
`;

function AnimatedSwitch(props) {
  const { children } = props;

  const location = useLocation();

  const transition = useTransition(location, {
    keys: (location) => location.pathname,
    from: { y: '100%', opacity: 0 },
    expires: true,
    enter: () => async (next) => {
      // fade in the new route
      await next({ opacity: 1 });
    },
    leave: () => async (next) => {
      // move AnimatedBlock in front of the route
      await next({ y: '0%', opacity: 1 });

      // fade out the route
      await next({ y: '0%', opacity: 0 });

      // move out AnimatedBlock
      await next({ y: '-100%', opacity: 0 });
    },
  });

  // returns a z-index
  function z(phase) {
    switch (phase) {
      case 'mount':
        return -1;
      case 'enter':
        return 0;
      case 'leave':
        return 1;
      default:
        return -1;
    }
  }

  return transition((style, item, transition) => (
    <>
      <animated.div
        style={{
          opacity: style.opacity,
          position: 'relative',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: z(transition.phase),
        }}
      >
        <Switch location={item}>{children}</Switch>
      </animated.div>

      <AnimatedBlock style={{ y: style.y }}>&nbsp;</AnimatedBlock>
    </>
  ));
}

export default AnimatedSwitch;
