import { modularScale } from 'polished';
import * as React from 'react';
import styled from 'styled-components/macro';

const Container = styled('div')`
  margin: 1.5em 0;
`;

const DefinitionList = styled('dl')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: -0.5rem;
`;

const DefinitionContainer = styled('div')`
  display: flex;
  flex-flow: column-reverse nowrap;
  margin: 0.5rem;
`;

const DefinitionTerm = styled('dt')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 1.2;
`;

const DefinitionDetails = styled('dd')`
  font-size: ${modularScale(2)};
  font-weight: 600;
  color: ${({ color }) => color};
`;

const KPIList = ({ indicators }) => {
  return (
    <Container>
      <DefinitionList>
        {indicators.map(({ label, value, color }) => (
          <DefinitionContainer key={label}>
            <DefinitionTerm>{label}</DefinitionTerm>
            <DefinitionDetails color={color}>{value}</DefinitionDetails>
          </DefinitionContainer>
        ))}
      </DefinitionList>
    </Container>
  );
};

export default KPIList;
