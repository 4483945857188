import { min, max } from 'd3-array';
import { interpolateRgbBasis } from 'd3-interpolate';
import { scaleDiverging } from 'd3-scale';
import { colors } from '../../../constants';

export function getColorScale(arr, fn, reverse = false) {
  let domain = [min(arr, fn), 0, max(arr, fn)]; // default for scaleDiverging
  let colorRange = reverse
    ? [...colors.diverging].reverse()
    : [...colors.diverging];
  let interpolator = interpolateRgbBasis(colorRange);

  return scaleDiverging()
    .domain(domain)
    .interpolator(interpolator);
}
