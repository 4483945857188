import styled from 'styled-components/macro';
import * as React from 'react';

const Origin = styled('circle')`
  fill: white;
  stroke: var(--red);
  stroke-width: 2;
`;

const Destination = styled('circle')`
  fill: var(--red);
  stroke: none;
`;

const Via = styled('circle')`
  fill: var(--red);
  stroke: none;
`;

export const Stop = ({ type, ...rest }) => {
  if (type === 'origin') return <Origin r={5} {...rest} />;
  if (type === 'via') return <Via r={3} {...rest} />;
  if (type === 'destination') {
    return (
      <g>
        <Origin r={5} {...rest} />
        <Destination r={2} {...rest} />
      </g>
    );
  }
};
