import { css } from 'styled-components/macro';

export const Stripe = css`
  content: '';
  position: absolute;
  width: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--red);
`;
