import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components/macro';

import { SubChapterTitle } from '../components/simple/SubChapterTitle';
import OriginDestinationData from '../components/origin-destination/OriginDestinationData';
import { SideScrollingGrid } from '../components/simple/SideScrollingGrid';
import { Step } from '../components/simple/Step';
import { SubChapterIntro } from '../components/simple/SubChapterIntro';
import { StepCounter } from '../components/StepCounter';
import useScrollama from '../hooks/useScrollama';
import Section from '../components/simple/Section';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import { avgJourneysWorkday } from '../constants';

const Background = styled('div')`
  background: var(--light-gray);
  min-width: 100%;
`;

const steps = [
  'before-10-false',
  'before-10,2-false',
  'before-10,2-10,2',
  'before-*-2',
  'before-*-*',
  'after-*-*',
  'change-*-*',
];

function OriginDestination(props) {
  const [ref, visible] = useIntersectionObserver(0.01);

  const [step, setStep] = useState('before');
  const [groups, setGroups] = useState(['10']);
  const [chords, setChords] = useState(['false']);

  const handleStepEnter = useCallback(function handleStepEnter(event) {
    let step, groups, chords;

    ({ step } = event.element.dataset);
    [step, groups, chords] = step.split('-');

    groups = groups.split(',');
    chords = chords.split(',');

    setStep(step);
    setGroups(groups);
    setChords(chords);
  }, []);

  const container = useRef();

  useScrollama(
    {
      container: container.current,
      step: '#origin-destination-container [data-step]',
    },
    handleStepEnter,
    'onStepEnter'
  );

  return (
    <Section id="650000-reizen" ref={ref}>
      <SubChapterIntro>
        <SubChapterTitle>
          {avgJourneysWorkday.toLocaleString('nl-NL')} reizen per werkdag
        </SubChapterTitle>
        <p>
          Een herkomst/bestemming analyse is een veelgebruikte tool voor een
          verkeersplanner. Het laat zien hoe en hoeveel mensen reizen tussen
          zones van Amsterdam.
        </p>
        <p>
          Op een gemiddelde werkdag reizen in Amsterdam bijna{' '}
          {avgJourneysWorkday.toLocaleString('nl-NL')} mensen van en naar hun
          bestemming. Waar komen ze vandaan en waar gaan ze heen?
        </p>
      </SubChapterIntro>
      <Background>
        <SideScrollingGrid ref={container} id="origin-destination-container">
          <SideScrollingGrid.Content>
            <OriginDestinationData
              activeStep={step}
              activeGroups={groups}
              activeChords={chords}
              legendLabel="verandering aantal reizen"
              dataAccessor="trips"
              isVisible={visible}
            />
          </SideScrollingGrid.Content>
          <SideScrollingGrid.SideStory>
            <Step data-step="before-10-false">
              <h4>Check-ins op Amsterdam Centraal in najaar 2017</h4>
              <p>
                Deze streep staat voor Amsterdam Centraal. De breedte van de
                streep laat zien hoeveel mensen in deze zone inchecken.
              </p>
              <StepCounter steps={steps} activeStep={steps[0]} />
            </Step>

            <Step data-step="before-10,2-false">
              <h4>
                Check-ins op Amsterdam Centraal en de Weesperbuurt in najaar
                2017
              </h4>
              <p>
                Deze tweede streep staat voor zone Weesperbuurt. Onder andere
                het Weesperplein valt hieronder. Elke zone in het Amsterdamse
                openbaar vervoer heeft zijn eigen streep. Ook hier staat de
                breedte voor het aantal reizigers dat in zone Weesperbuurt
                incheckt.
              </p>
              <StepCounter steps={steps} activeStep={steps[1]} />
            </Step>

            <Step data-step="before-10,2-10,2">
              <h4>
                Reizen tussen Amsterdam Centraal en de Weesperbuurt in najaar
                2017
              </h4>
              <p>
                Deze lijn staat voor alle mensen die reizen tussen zone
                Weesperbuurt en Amsterdam Centraal.
              </p>
              <p>
                De reisrelatie tussen Amsterdam Centraal en het Weesperplein is
                een van de drukste van de stad, met dagelijks rond de 9,000
                reizigers.
              </p>
              <StepCounter steps={steps} activeStep={steps[2]} />
            </Step>

            <Step data-step="before-*-2">
              <h4>
                Reizen tussen de Weesperbuurt en alle andere zones in najaar
                2017
              </h4>
              <p>
                Nu zijn alle reizigersstromen vanuit zone Weesperbuurt te zien.
                De meeste reizigers reizen naar Amsterdam Centraal.
              </p>
              <StepCounter steps={steps} activeStep={steps[3]} />
            </Step>

            <Step data-step="before-*-*">
              <h4>Reizen tussen alle zones in najaar 2017</h4>
              <p>
                De lijnen laten stromen reizigers zien tussen zones. Door te
                hoveren over een zone kun je zien hoeveel mensen daar in een
                maand inchecken en waar ze naar toe reizen.
              </p>
              <StepCounter steps={steps} activeStep={steps[4]} />
            </Step>

            <Step data-step="after-*-*">
              <h4>Reizen tussen alle zones in najaar 2018</h4>
              <p>
                Tot zover heb je gekeken naar data uit 2017, vóór de
                ingebruikname van de Noord/Zuidlijn. Deze nieuwe data laat zien
                hoe mensen reizen na de ingebruikname van de Noord/Zuidlijn.
              </p>
              <p>
                Het is interessant om te zien hoeveel zowel zone Noord-Midden
                als Zuid gegroeid zijn in reizigersvolume.
              </p>
              <StepCounter steps={steps} activeStep={steps[5]} />
            </Step>

            <Step data-step="change-*-*">
              <h4>
                Verandering in reizigersstromen tussen najaar 2017 en najaar
                2018
              </h4>
              <p>
                Hier is duidelijk de impact van de Noord/Zuidlijn op reizigers
                in Amsterdam te zien. Vanuit elke zone waar een Noord/Zuid
                metrostation ligt (Noord-Midden, Centraal, Spui (Rokin),
                Vijzelgracht, de Pijp) is groei te zien in de richting van de
                andere zones.
              </p>
              <p>
                Ook andere effecten van de Noord/Zuidlijn zijn te zien:
                reizigers uit IJburg en de Oostelijke Eilanden zijn vaker via
                Centraal Station gaan reizen, waarschijnlijk om daar over te
                stappen op de Noord/Zuidlijn.
              </p>
              <StepCounter steps={steps} activeStep={steps[6]} />
            </Step>
          </SideScrollingGrid.SideStory>
        </SideScrollingGrid>
      </Background>
    </Section>
  );
}

export default OriginDestination;
