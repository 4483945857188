import styled from 'styled-components/macro';
import { modularScale } from '../../helpers/modular-scale';

export const Step = styled('div')`
  margin: 100vh 0 100vh 0;
  padding: 2em 0.75em;
  background: rgba(255, 255, 255, 0.9);
  font-size: 0.9em;
  pointer-events: auto;

  @media screen and (min-width: 45em) {
    margin: 50vh 0;
    padding: 2em;
    background: rgba(255, 255, 255, 1);
    font-size: 1em;
  }

  h4 {
    margin-top: 0;
    font-size: ${modularScale(1)};
    font-weight: 600;
    font-family: var(--harmonia);
  }

  p {
    margin: 1.5em 0;
  }

  &:last-of-type {
    margin-bottom: 120vh;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
