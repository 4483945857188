import styled from 'styled-components/macro';

const SideScrollingGrid = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 1fr minmax(100%, 27.5em);
  align-items: start;
  min-height: 100vh;
  width: 100%;
  padding: 0;

  @media screen and (min-width: 45em) {
    grid-template-columns: 1fr 27.5em;
  }
`;

const Left = styled('div')`
  grid-column: 1 / span 2;
  grid-row: 1;

  position: sticky;
  top: 0;

  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;

  padding: 0;
  overflow: hidden;
`;

const Right = styled.div`
  pointer-events: none;
  grid-column: 2;
  grid-row: 1;
  z-index: 2;
  @media screen and (min-width: 45em) {
    margin-right: 1.5rem;
  }
`;

SideScrollingGrid.Content = Left;
SideScrollingGrid.SideStory = Right;

export { SideScrollingGrid };
