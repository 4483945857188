import * as React from 'react';

import { Nav, List, ListItem, Link } from './styled';

export const Navigation = () => (
  <Nav id="navigation" aria-label="primaire navigatie">
    <List>
      <ListItem>
        <Link to="/" exact>
          Introductie
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/verandering-in-het-openbaar-vervoer">
          Verandering in het openbaar vervoer
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/verandering-voor-de-reiziger">
          Verandering voor de reiziger
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/impact-van-de-noord-zuid-lijn">
          De impact van de Noord/Zuidlijn
        </Link>
      </ListItem>
    </List>
  </Nav>
);
