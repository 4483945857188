import * as React from 'react';
import styled from 'styled-components/macro';
import { format } from 'd3-format';
import { interpolate, quantize } from 'd3-interpolate';
import { modularScale } from '../../helpers/modular-scale';

const Tick = styled('text')`
  font-family: var(--roboto-condensed);
  font-size: ${modularScale(-1)};
  font-weight: 700;
  text-transform: uppercase;
`;

const Label = styled(Tick)`
  font-weight: 400;
`;

export const Axis = ({ x, binX, height, type, sampleCount = 5 }) => {
  let interpolator = interpolate(...binX.domain());
  let samples = quantize(interpolator, sampleCount);
  let formatter = format(type === 'change' ? '+,.2r' : ',.2r');

  return (
    <g transform={`translate(0, ${height / 2})`}>
      <Label x={x.range()[1]} y={40} textAnchor="end">
        {type === 'change' ? 'groei/daling' : ''} ritten per dag
      </Label>

      {samples.map((tick, i) => (
        <g key={tick} transform={`translate(${x(tick)}, 0)`}>
          <Tick
            x={0}
            y={0}
            dy={20}
            textAnchor={
              i === 0 ? 'start' : i === samples.length - 1 ? 'end' : 'middle'
            }
          >
            {`${type === 'change' && tick > 0 ? '+' : ''}${
              tick === 0 ? tick : formatter(tick)
            }`}
          </Tick>
        </g>
      ))}
    </g>
  );
};
