import styled from 'styled-components/macro';

export const OpacityTransitionGroup = styled('g')`
  opacity: ${({ show, startOpacity = 0, endOpacity = 1 }) =>
    show ? endOpacity : startOpacity};
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  transition-delay: ${({ delay = 0 }) => `${delay}ms`};
`;
