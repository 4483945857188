import { easeQuad } from 'd3-ease';
import * as React from 'react';
import { useTransition, animated } from 'react-spring';
import { round } from './DataGroup';

export default React.memo(NodeLayer);

function NodeLayer(props) {
  const { colorScale, nodeRadius, nodes, type } = props;

  const transition = useTransition(nodes, {
    keys: (node) => `node-${node.id}`,
    config: { duration: 400, easing: easeQuad },
    from: (node) => ({
      cx: round(node[type]?.x) ?? '50%',
      cy: round(node[type]?.y) ?? '50%',
      fill: colorScale(node.frequency),
    }),
    update: (node) => ({
      cx: round(node[type]?.x) ?? '50%',
      cy: round(node[type]?.y) ?? '50%',
      fill: colorScale(node.frequency),
    }),
  });

  return (
    <g className="nodes">
      {transition((style, item) => (
        <animated.circle
          cx={style.cx}
          cy={style.cy}
          fill={style.fill}
          r={nodeRadius}
        />
      ))}
    </g>
  );
}
