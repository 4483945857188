import { formatDefaultLocale } from 'd3-format';
import * as React from 'react';
import { render } from 'react-dom';
import { enableAllPlugins } from 'immer';

import { locale } from './constants';
import App from './App';

enableAllPlugins();

// Set the Dutch (nl-NL) locale as the default locale.
formatDefaultLocale(locale);

render(<App />, document.getElementById('root'));
